import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './notification.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const Group = (props: IHeaderProps) => {

  const initialFormData = {
    id: "0",
    groupName: null,
    parentFirst: null,
    parentSecond: null,
    levelGroup: null,
    dataAdminHC: null,
    documentCode: null
  }

  const dataStatus = [
    {
      label: "All Status",
      value: "all"
    },
    {
      label: "Active",
      value: "true"
    },
    {
      label: "Inactive",
      value: "false"
    }
  ]

  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [fullname, setFullName] = useState<any>("")
  const [iddelete, setIdDelete] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [formData, setFormData] = useState(initialFormData)
  const [listKaryawan, setListKaryawan] = useState<any>([])
  const [showModalAdd, setshowModalAdd] = useState<any>(false)
  const [showModalDel, setShowModalDel] = useState<any>(false)
  const [dataParentFirst, setDataParentFirst] = useState<any>([])
  const [dataParentSecond, setDataParentSecond] = useState<any>([])
  const [dataLevelGroup, setDataLevelGroup] = useState<any>([])
  const [dataAdminHC, setDataAdminHC] = useState<any>([])
  const [formEdit, setFormEdit] = useState(false)
  const [valActivated, setValActivated] = useState<any>("all");
  const [sort, setSort] = useState<any>("groupName")
  const [ascending, setAscending] = useState<any>(true)
  const [addStatus, setAddStatus] = useState<any>("")


  useEffect(() => {
    getDataGroupKaryawan()
    getDataParentFirst()
    getListAdminHC()
  }, [page, size, totalPage, keyword, valActivated, sort, ascending]);

  // get data
  const getDataGroupKaryawan = () => {
    var valAsc = ascending ? 'asc' : 'desc';
    axios.get(`services/uaadocservice/api/group/get-list-group?keyword=${keyword}&page=${page}&size=${size}&statusFilter=${valActivated}&sort=${sort + ',' + valAsc}`)
      .then((res) => {
        setListKaryawan(res.data.data.content)
        setTotalPage(res.data.data.totalPages)
      }).catch((err) => {
        throw new Error('getDataGroupKaryawan')
      })
  }

  const getDataParentFirst = () => {
    axios.get('services/uaadocservice/api/level-organization/dropdown-level-organization-for-parent-group?levelOrganizationId=0').then(res => {
      const dataParent = [];
      res.data.map((val, i) => {
        dataParent.push({ value: val.LevelParentGroupId, label: val.levelParentGroupName });
      })
      setDataParentFirst(dataParent);
    }).catch(err => {
      throw new Error('levelOrganizationId error')
    })
  }

  const getDataParentSecond = (val) => {
    axios.get(`services/uaadocservice/api/group/get-by-level-organization?levelOrganizationId=${val}`).then(res => {
      const dataParent = [];
      res.data.map((val, i) => {
        dataParent.push({ value: val.parentGroupId, label: val.parentGroupName });
      })
      setDataParentSecond(dataParent)
    }).catch(err => {
      throw new Error('getDataParentSecond error')
    })
  }

  const getLevelGroup = (val) => {
    axios.get(`services/uaadocservice/api/level-organization/dropdown-level-group?parentGroupid=${val}`).then(res => {
      const dataLevelGroup = [];
      res.data.map((val, i) => {
        dataLevelGroup.push({ value: val.levelOrganizationId, label: val.levelOrganizationName });
      })
      setDataLevelGroup(dataLevelGroup)
    }).catch(err => {
      throw new Error('getLevelGroup error')
    })
  }

  const getListAdminHC = () => {
    axios.get(`services/uaadocservice/api/group-authority/get-list-admin-hc`).then((res) => {
      const dataListAdminHC = [];
      res.data.map((val, i) => {
        dataListAdminHC.push({ value: val.employeeNo, label: val.fullName });
      })
      setDataAdminHC(dataListAdminHC)
    }).catch((err) => {
      throw new Error('getListAdminHC error')
    })
  }

  const onChangeStatus = (event) => {
    setAddStatus(event.target.value)
  }

  // click button
  const gotoDetailGroup = (id) => {
    axios.get(`/services/uaadocservice/api/group/get-detail/${id}`).then((res) => {
      history.push(`/group/detail/${id}`)
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const editFormGroup = (idEdit) => {
    // kurang modal
    axios.get(`services/uaadocservice/api/group/get-detail/${idEdit}`).then((res) => {
      const valData = res.data.data
      setFormData({
        ...formData,
        id: valData.id,
        groupName: valData.groupName,
        parentFirst: valData.levelParentGroup,
        parentSecond: valData.parentGroupId,
        levelGroup: valData.levelOrganizationId,
        dataAdminHC: valData.adminHC,
        documentCode: valData.documentCode
      })
      setAddStatus(valData.status == true ? "Active" : "Inactive")
      getDataParentSecond(valData.levelParentGroup)
      getLevelGroup(valData.parentGroupId)
      setFormEdit(true)
      setshowModalAdd(true)
    }).catch((err) => {
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const handleInputParentFirst = (name, value) => {
    setFormData({
      ...formData,
      [name]: value.value,
      parentSecond: "",
      levelGroup: "",
    })
    getDataParentSecond(value.value)
  }

  const handleInputParentSecond = (name, value) => {
    setFormData({
      ...formData,
      [name]: value.value,
      levelGroup: "",
    })
    getLevelGroup(value.value)
  }

  const handleInputLevelGroup = (name, value) => {
    setFormData({
      ...formData,
      [name]: value.value,
    })
  }

  const handleInput = (name, value) => {
    setFormData({
      ...formData,
      [name]: value.value
    })
  }

  // action
  const defaultVal = () => {
    setFormData({
      ...formData,
      id: "0",
      groupName: null,
      parentFirst: null,
      parentSecond: null,
      levelGroup: null,
      dataAdminHC: null,
      documentCode: null
    })
    setFormEdit(false)
  }

  const postDataGroup = () => {
    if (!formData.groupName || !formData.parentFirst || !formData.parentSecond || !formData.levelGroup || !formData.dataAdminHC) {
      toast.error((`${"Please check field required"}`));
    } else {
      setLoading(true)
      const formDataFinal = new FormData();
      let dataFormData = {
        id: formData.id,
        groupName: formData.groupName,
        parentGroupId: formData.parentSecond,
        levelOrganizationId: formData.levelGroup,
        adminHC: formData.dataAdminHC,
        documentCode: formData.documentCode,
        isActive: addStatus == "Active" ? true : false
      }
      formDataFinal.append("data", JSON.stringify(dataFormData));
      axios.post('services/uaadocservice/api/group/save', formDataFinal).then(res => {
        toast.success('Success Add Group');
        handleCloseAdd()
        setLoading(false)
        getDataGroupKaryawan()
        defaultVal()
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
        setLoading(false)
        handleCloseAdd()
        defaultVal()
      })
    }
  }

  const postEditDataGroup = () => {
    if (!formData.groupName || !formData.parentFirst || !formData.parentSecond || !formData.levelGroup || !formData.dataAdminHC) {
      toast.error((`${"Please check field required"}`));
    } else {
      setLoading(true)
      const formDataFinal = new FormData();
      let dataFormData = {
        id: formData.id,
        groupName: formData.groupName,
        parentGroupId: formData.parentSecond,
        levelOrganizationId: formData.levelGroup,
        adminHC: formData.dataAdminHC,
        documentCode: formData.documentCode,
        isActive: addStatus == "Active" ? true : false
      }
      formDataFinal.append("data", JSON.stringify(dataFormData));
      axios.post('services/uaadocservice/api/group/save', formDataFinal).then(res => {
        toast.success('Success Edit Group');
        setLoading(false)
        setFormEdit(false)
        getDataGroupKaryawan()
        handleCloseAdd()
        defaultVal()
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`));
        setLoading(false)
        setFormEdit(false)
        getDataGroupKaryawan()
        handleCloseAdd()
        defaultVal()
      })
    }
  }

  const deleteGroup = () => {
    setLoadingDelete(true)
    axios.delete(`services/uaadocservice/api/group/delete/${iddelete}`).then(res => {
      handleCloseDel()
      setLoadingDelete(false)
      setIdDelete(null)
      getDataGroupKaryawan()
      defaultVal()
      toast.success('Success Delete Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      defaultVal()
      getDataGroupKaryawan()
      setLoadingDelete(false)
      toast.error('Failed Delete Group', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  // modal
  const handleCloseAdd = () => {
    setshowModalAdd(false)
    defaultVal()
  }

  const handleCloseDel = () => {
    setShowModalDel(false)
  }

  // pagination
  const handleChange = (event) => {
    setPage(1)
    setSize(parseInt(event.target.value));
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="px-5 pt-4 font-family-spoqa" style={{ height: '100%' }}>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Group Karyawan</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">Mirae Asset DMS - Group Karyawan</h1>
          </div>
        </div>
      </div>
      <div className='row my-3'>
        <div className="col-12 col-md-3 col-lg-3">
          <div className='btn btn-primary w-100 py-2' onClick={() => setshowModalAdd(true)}>
            <UploadDocumentListIcon /> Buat Group
          </div>
        </div>
      </div>
      <div className='row my-3'>
        <div className="col-12 col-md-9 col-lg-9 px-2">
          <div className="app-search w-100 py-0 bg-white">
            <div className="position-relative">
              <input type="text"
                className="form-control bg-white py-3"
                placeholder="Search group karyawan"
                onChange={(e) => {
                  if (e.target.value === '') {
                    setKeyword(e.target.value)
                    getDataGroupKaryawan()
                  } else {
                    setKeyword(e.target.value)
                  }
                }} onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    setPage(1)
                    getDataGroupKaryawan()
                  }
                }} />
              <span className="bx bx-search-alt my-0"></span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-3 px-2">
          <Select
            placeholder={"Select Status"}
            defaultValue={{ label: "All Status", value: "all" }}
            options={dataStatus}
            onChange={(e) => setValActivated(e.value)}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-centered  mb-0">
              <thead className="thead-light">
                <tr>
                  {/* <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('groupName')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Group Name</span>
                      <span className='mt-1'>
                        {(sort == "groupName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "groupName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "groupName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('levelOrganizationName')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Level Organization</span>
                      <span className='mt-1'>
                        {(sort == "levelOrganizationName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "levelOrganizationName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "levelOrganizationName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('adminHCName')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Handled By Admin</span>
                      <span className='mt-1'>
                        {(sort == "adminHCName" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "adminHCName" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "adminHCName") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('totalMember')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Total Member</span>
                      <span className='mt-1'>
                        {(sort == "totalMember" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "totalMember" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "totalMember") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th>
                  <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                    setSort('isActive')
                    setAscending(!ascending)
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='mr-1'>Status</span>
                      <span className='mt-1'>
                        {(sort == "isActive" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                        {(sort == "isActive" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                        {(sort != "isActive") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                      </span>
                    </div>
                  </th> */}
                  <th>Group Name</th>
                  <th>Level Organization</th>
                  <th>Handled By Admin</th>
                  <th>Total Member</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  listKaryawan ? listKaryawan.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className='py-2' style={{ wordBreak: 'break-all', cursor: 'pointer', color: '#556ee6' }} onClick={() => gotoDetailGroup(item.id)}>{item.groupName ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all' }}>{item.levelOrganizationName ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all' }}>{item.adminHCName ?? "-"}</td>
                        <td style={{ wordBreak: 'break-all' }}>{item.totalMember}</td>
                        <td style={{ wordBreak: 'break-all', minWidth: '100px' }}>
                          {
                            item?.status == "Active" ?
                              <span style={{ fontSize: '12px', background: '#43936C', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                Active
                              </span>
                              :
                              <span style={{ fontSize: '12px', background: '#CB3A31', color: '#FFFFFF', borderRadius: '5px', padding: '5px', margin: '5px', display: 'inline-block', verticalAlign: 'top' }}>
                                Inactive
                              </span>
                          }
                        </td>
                        <td style={{ wordBreak: 'break-all', width: '10%' }} className='fix'>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <button
                              className='btn mr-2'
                              style={{ backgroundColor: '#B1C5F6', borderColor: '#B1C5F6', color: '#3267E3' }}
                              onClick={() => editFormGroup(item.id)}
                            >
                              <FontAwesomeIcon icon="pencil-alt" />{' '}Edit
                            </button>
                            {/* <button
                              className='btn mr-2'
                              style={{ backgroundColor: '#EEB4B0', borderColor: '#EEB4B0', color: '#CB3A31' }}
                              onClick={() => {
                                setShowModalDel(true)
                                setFullName(item.groupName)
                                setIdDelete(item.id)
                              }}>
                              <FontAwesomeIcon icon="trash" />{' '}Delete
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    )
                  }) : <></>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
          <div style={{ display: 'flex' }}>
            <span className="mt-3 mx-2">Show</span>
            <span>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                <SelectMui
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={size}
                  onChange={handleChange}
                  label="Limit">
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </SelectMui>
              </FormControl>
            </span>
            <span className="mt-3 mx-2">
              Entries
            </span>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
          <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
        </div>
      </div>
      <Modal className="font-family-spoqa" isOpen={showModalAdd} toggle={handleCloseAdd} size="lg" centered={true}>
        <ModalHeader toggle={handleCloseAdd}>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between pb-0">
                <h1 className="mb-2 font-size-24">Buat Group</h1>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h1 className="mb-0 font-size-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row ml-2 mr-2">
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Group Name
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formData.groupName}
                  placeholder="Group Name ex : Information Technology"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      groupName: e.target.value
                    })}
                // disabled={!formEdit ? false : true}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Parent Group
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-4" style={{ alignSelf: 'center' }}>
                <Select
                  name="group"
                  value={dataParentFirst.filter((function (option: any) {
                    return option.value == formData.parentFirst
                  }))}
                  placeholder="Level Organization"
                  options={dataParentFirst}
                  onChange={(e) => handleInputParentFirst("parentFirst", e)}
                />
              </div>
              <div className="col-5" style={{ alignSelf: 'center' }}>
                <Select
                  name="group"
                  value={dataParentSecond.filter((function (option: any) {
                    return option.value == formData.parentSecond
                  }))}
                  placeholder="Select Organization"
                  options={dataParentSecond}
                  onChange={(e) => handleInputParentSecond("parentSecond", e)}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Level Group
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <Select
                  name="group"
                  value={dataLevelGroup.filter((function (option: any) {
                    return option.value == formData.levelGroup
                  }))}
                  placeholder="Select Level Group"
                  options={dataLevelGroup}
                  onChange={(e) => handleInputLevelGroup("levelGroup", e)}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Admin HC
                  <span className="required" style={{ color: '#ff0000' }}>*</span>
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <Select
                  name="group"
                  value={dataAdminHC.filter((function (option: any) {
                    return option.value == formData.dataAdminHC
                  }))}
                  placeholder="Select Level Group"
                  options={dataAdminHC}
                  onChange={(e) => handleInput("dataAdminHC", e)}
                />
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Document Code
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                <input
                  className="form-control"
                  value={formData.documentCode}
                  placeholder="Document Code ex : IT"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      documentCode: e.target.value
                    })}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-3" style={{ alignSelf: 'center' }}>
                <div className='font-weight-bold'>
                  Status
                </div>
              </div>
              <div className="col-9" style={{ alignSelf: 'center' }}>
                {/* <input
                  className="form-control"
                  value={formData.documentCode}
                  placeholder="Document Code ex : IT"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      documentCode: e.target.value
                    })}
                /> */}
                <div className="row">
                  <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                    <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                      <input type="radio"
                        checked={addStatus === "Active"}
                        value={"Active"}
                      />
                      <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                        Active
                      </span>
                    </label>
                  </div>
                  <div className="col-12 col-md-2 col-lg-2 px-3" onChange={event => onChangeStatus(event)}>
                    <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                      <input type="radio"
                        checked={addStatus === "Inactive"}
                        value={"Inactive"}
                      />
                      <span style={{ fontSize: '12px', marginLeft: '10px' }}>
                        Inactive
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ background: '#F37F26', borderColor: '#F37F26' }}
            disabled={loading}
            onClick={() => !formEdit ? postDataGroup() : postEditDataGroup()}>
            <span className='mr-1'>
              {loading ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
              {!formEdit ? 'Save' : 'Update'}
            </span>
          </Button>
        </ModalFooter>
      </Modal>
      <Modal className="font-family-spoqa" isOpen={showModalDel} toggle={handleCloseDel} size="md" style={{ marginTop: '15%' }}>
        <ModalHeader toggle={handleCloseDel}>
          <div className="w-100">
            <h2 className="">Delete Confirmation</h2>
            <h3 style={{ fontSize: '12px' }}>{`Apakah anda yakin menghapus ${fullname} ?`}</h3>
          </div>
        </ModalHeader>
        <div className="my-4">
          <Row className='w-100'>
            <Col lg={12} className="text-right">
              <Button color="secondary" onClick={handleCloseDel} className="mr-2">
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success px-4" onClick={() => deleteGroup()} disabled={loadingDelete}>
                {loadingDelete ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Yes
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(Group);
