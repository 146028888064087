import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    subAccountNoList?: any,
    date?: any
    priceDigit?: any
}

export const CreateNewTCResult = (props: IHeaderProps) => {
    const initialFormData = {
        levelGroup: null,
        selectGroup: null
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("levelOrganizationName")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListCreateNewTCResult, setDataListCreateNewTCResult] = useState<any>([])
    const [dataDownloadResult, setDataDownloadResult] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [dataLevelOrganization, setDataLevelOrganization] = useState<any>([])
    const [dataOrganization, setDataOrganization] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState(new Date())

    const parameterSubAcc = props.location.state.subAccountNoList as IParams
    const parameterDate = props.location.state.date as IParams
    const parameterPriceDigit = props.location.state.priceDigit as IParams

    useEffect(() => {
        getListCreateNewTCResult();
    }, []);

    // get data
    const getListCreateNewTCResult = () => {
        let listAccountNo = parameterSubAcc
        let date = parameterDate
        let priceDigit = parameterPriceDigit
        axios.get(`services/consolidatedportfolioservice/api/asset-management/${date}/create-tc?subAccountNumbers=${listAccountNo}&decimal=${priceDigit}`).then((res) => {
            setDataListCreateNewTCResult(res.data.data)
        }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    const downloadFilePDF = (param) => {
        let id = param.id
        let code = param.sub_account_no
        let tradeDate = param.order_date

        axios({
        url: `${window.location.origin}/services/consolidatedportfolioservice/api/asset-management/get-pdf?subAccountId=${id}`,
        method: 'GET',
        responseType: 'blob',
        }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DMS-${id}-${code}-${tradeDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 100);
        });
    }

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start">
                        <button className="btn px-0" onClick={() => history.push(`/investmentmanager/historygeneratetc`)}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
                        </button>
                        <h1 className="font-family-spoqa mb-0 font-size-28 px-2"> Result</h1>
                    </div>
                </div>
            </div>
            {dataListCreateNewTCResult ? dataListCreateNewTCResult?.map((item, index) => {
                return (
                    <div className="card font-family-spoqa">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-3 col-lg-6 px-2">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h1 className="font-family-spoqa mb-0 font-size-24 px-2"> {item.sub_account_no} - {item.sub_account_name}</h1>
                                    </div>
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">Last Generate: {item.created_date}</h1>
                                    </div>
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">Generate by {item.created_by_name} ({item.created_by})</h1>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-6 px-2 py-4">
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-md-3 col-lg-4 px-2">
                                            <button className='btn btn-primary btn-lg' onClick={() => downloadFilePDF(item)}>
                                                <span className="bx bxs-file-pdf my-0"></span> Download TC
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ background: '#F37F26' }} />
                            <div className="row justify-content-between">
                                <div className="col-12 col-md-3 col-lg-4 px-2 py-4">
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">NPWP</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.npwp}</h1>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">SID</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.sid}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-4 px-2 py-4">
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">Trade Date</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.order_date}</h1>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">Sales</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.sales}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 col-lg-4 px-2 py-4">
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">PIC</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.pic}</h1>
                                        </div>
                                    </div>
                                    <div className='py-2'>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2">Position</h1>
                                        </div>
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h1 className="font-family-spoqa mb-0 font-size-14 px-2 my-n2 fw-bold">{item.pic_position}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

                : <></>
        }
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTCResult);