import React from 'react';

const ErrorMessage = ({ message }) => {
    if (!message) return null;

    return (
        <div style={errorStyle}>
            {message}
        </div>
    );
};

const errorStyle: React.CSSProperties = {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '8px',
    fontSize: '12px',
};

export default ErrorMessage;
