import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './announcement.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { Editor } from '@tinymce/tinymce-react';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import AccountAnnounceIcon from 'app/component/announ-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import PencilIcon from 'app/component/pencil-icon';
import { toast } from 'react-toastify';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import FilesDragAndDrop from 'app/component/dragUpload';
import DragUploadIcon from 'app/component/upload-drag-icon';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import { MultiSelect } from "react-multi-select-component"

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any,
  logout?: any,
  location?: any
}


export const AnnoucementCreate = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('')
  const [listSearch, setListSearch] = useState([])
  const [loadingList, setLoadingList] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [size, setSize] = useState<any>(10)
  const [sortType, setSortType] = useState('typeName.keyword,asc')
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc')
  const [sortLastModified, setSortLastModified] = useState('lastApprovedByName.keyword,asc')
  const [sortDate, setSortDate] = useState('date,asc')
  const [limit, setLimit] = useState<any>(5)
  const [listAnnouncement, setListAnnouncement] = useState<any>([])
  const [showModal, setShowModal] = useState<any>(false)
  const [selectedItem, setItemSelected] = useState<any>(null)
  const [loadingDelete, setLoadingDelete] = useState<any>(false)
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([])
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([])
  const [fileContent, setFileContent] = useState<any>('')
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [title, setTile] = useState<any>('')
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false)

  const onDropHandler = (ev) => {
    ev.preventDefault();

    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file =
        [...ev.dataTransfer.items]
          .find((item: any) => item.kind === "file")
          .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }
    handlerChangeImage(file)
    // setFiles(file);
    // setUrlImage(URL.createObjectURL(file))
  };

  const onDragOver = (ev) => ev.preventDefault();

  useEffect(() => {
    // alert('data search', props.location.state.search)
    // alert(props.location.state.search)
    if (props.location.state?.search == '') {
      setListSearch([])
      setDefault()
    } else {
      getSearch(props.location.state?.search)
    }

  }, [props.location.state?.search, sortType, sortPriority, sortLastModified, sortDate, page, size, totalPage]);






  useEffect(() => {
    // alert(props.location.state.search)
    getSearch(props.location.state?.search)
    getListAnnouncement(1, 10)
    setDate(new Date())
    setPostDate(new Date())
    getUserList()
    getDepartmentList()
  }, []);

  const setDefault = () => {
    setSortType('typeName.keyword,asc')
    setSortPriority('priority.keyword,asc')
    setSortLastModified('lastApprovedByName.keyword,asc')
    setSortDate('date,asc')
  }

  const getListAnnouncement = (page, limit) => {
    axios.get(`services/documentservice/api/announcement/dashboard?page=${page}&size=${limit}`)
      .then(res => {
        setListAnnouncement(res.data.data?.content)
        setTotalPage(res.data.data?.totalPages)
      })
  }


  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }


  const getSearch = async (e = '') => {
    if (e.length > 1) {
      setLoadingList(true)
      axios.get(`services/documentservice/api/custom-search/documents?keyword=${e}&page=${page}&size=${size}&sort=${sortType}&sort=${sortPriority}&sort=${sortLastModified}&sort=${sortDate}`)
        .then(async (result) => {
          setListSearch(result.data.content)
          setTotalPage(result.data?.totalPages)
          await delayTime(2000)
          setLoadingList(false)
        }).catch(err => {
          setListSearch([])
          setLoadingList(false)
        })
    }
  }


  const changeSortType = () => {
    if (sortType == 'typeName.keyword,asc') {
      setSortType('typeName.keyword,desc')
    } else {
      setSortType('typeName.keyword,asc')
    }
  }

  const changeSortPriority = () => {
    if (sortPriority == 'priority.keyword,asc') {
      setSortPriority('priority.keyword,desc')
    } else {
      setSortPriority('priority.keyword,asc')
    }
  }

  const changeSortLastModified = () => {
    if (sortLastModified == 'lastApprovedByName.keyword,asc') {
      setSortLastModified('lastApprovedByName.keyword,desc')
    } else {
      setSortLastModified('lastApprovedByName.keyword,asc')
    }
  }

  const changeSortDate = () => {
    if (sortDate == 'date,asc') {
      setSortDate('date,desc')
    } else {
      setSortDate('date,asc')
    }
  }

  const goToPage = (id) => {
    setSearch('')
    history.push(`/preview/only/pdf/${id}`)
  }

  const getUserList = () => {
    axios.get('services/uaadocservice/api/users/get-list-user-not-in-employee-no')
      .then(res => {
      }).catch(err => {
        console.log(err)
      })
  }

  const isValidFileUploaded = (file) => {
    if (file.type == "") {
      return true
    } else {
      const validExtensions = ['png', 'jpg', 'jpeg']
      const fileExtension = file.type.split('/')[1]
      return validExtensions.includes(fileExtension)
    }

  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 20) {
      return false
    } else {
      return true
    }
  }


  const getDepartmentList = () => {
    axios.get('services/uaadocservice/api/users/get-active-group')
      .then(res => {
        if (res.data.length > 1) {
          res.data.map((data, i) => {
            setListTo(oldArray => [...oldArray, { label: data.groupName, value: data.id }]);
          })
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    getListAnnouncement(value, limit)
  }

  const changeShow = (e) => {
    let show = e.target.value
    setPage(1)
    setLimit(show)
    getListAnnouncement(1, show)
    // getListDocumentHistori(1, show)
  }

  const handleClose = () => {
    setShowModal(false)
  };

  const openModalDelete = (item) => {
    setShowModal(true)
    setItemSelected(item)
  }

  const deleteFile = (id) => {
    setLoadingDelete(true)
    axios.delete(`services/documentservice/api/announcement/${id}`).then(res => {
      setLoadingDelete(false)
      toast.success('Success Delete Annoucement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowModal(false)
      setItemSelected(null)
      setPage(1)
      setLimit(5)
      getListAnnouncement(1, 5)
    }).catch(err => {
      setLoadingDelete(false)
      toast.error('Failed Delete Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const handlerChangeImage = (data) => {
    if (!data) return

    if (!isValidFileUploaded(data)) {
      toast.error('Tipe file tidak diperbolehkan', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      //file is valid
      if (!validateSize(data)) {
        toast.error('Ukuran file Tidak Boleh Lebih Dari 10MB', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {

        setFiles(data)
        setUrlImage(URL.createObjectURL(data))
      }
    }

  }



  const saveDraft = (e) => {
    e.preventDefault()
    setLoadingCreate(true)
    let toDept = []

    if (to.length > 0) {
      to.map((item, index) => {
        toDept.push({
          branchCode: item.value
        })
      })
    }

    const formData = new FormData();
    let payloaddata = {
      id: "0",
      title: title,
      fileContent: fileContent,
      to: toDept
    }

    // formDataa.append("data", JSON.stringify(payloaddata));
    formData.append("data", JSON.stringify(payloaddata))
    formData.append("image", files);
    axios.post(`services/documentservice/api/announcement/save-as-draft`, formData).then(res => {
      toast.success('Success Create Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoadingCreate(false)
      history.replace(`/announcement/list`)
    }).catch(err => {
      setLoadingCreate(false)
      toast.error(translate(`${err.response.data.message}`));
    })
  }

  const saveAndPublish = (e) => {
    setLoadingCreate(true)
    e.preventDefault()
    let toDept = []

    if (to.length > 0) {
      to.map((item, index) => {
        toDept.push({
          branchCode: item.value
        })
      })
    }

    const formData = new FormData();
    let payloaddata = {
      id: "0",
      title: title,
      fileContent: fileContent,
      to: toDept
    }

    // formDataa.append("data", JSON.stringify(payloaddata));
    formData.append("data", JSON.stringify(payloaddata))
    formData.append("image", files);
    axios.post(`services/documentservice/api/announcement/save-and-publish`, formData).then(res => {
      setLoadingCreate(false)
      toast.success('Success Create and Publish Announcement', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.replace(`/announcement/list`)
    }).catch(err => {
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoadingCreate(false)
      console.log(err)

    })
  }



  const limitString = (data: any, length: number) => {
    if (!data) return data
    if (data.length > length) {
      return data.slice(0, length)
    } else {
      return data
    }
  }


  return (
    <div className="px-5 pt-4" style={{ height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-28">Create Announcement</h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="font-family-spoqa mb-0 font-size-14">Mirae Asset Sekuritas Announcement Management</h1>
          </div>
        </div>
      </div>
      {/* <div className="bg-white rounded px-3 py-4 shadow my-4">
        <div className="row">
            <div className="col-12 col-xs-12 col-md-6 col-lg-6 my-2">
                <div className="row">
                  <div className="col-12 col-xs-12 col-md-2 col-lg-2">
                    Date
                  </div>
                  <div className="col-12 col-xs-12 col-md-10 col-10">
                  <DatePicker
                      selected={date}
                      onChange={(date) => { 
                        console.log(date)
                        setDate(date)}}
                      minDate={new Date()}  
                      dateFormat="d MMMM yyyy"
                      value={date}
                    />
                  </div>
                </div>
            </div>
            <div className="col-12 col-xs-12 col-md-6 col-lg-6 my-2">
              <div className="row">
                <div className="col-12 col-xs-12 col-md-2 col-lg-2">
                    Post Date
                  </div>
                  <div className="col-12 col-xs-12 col-md-10 col-10">
                  <DatePicker
                      selected={postDate}
                      onChange={(date) => setPostDate(date)}
                      minDate={new Date()}
                      dateFormat="d MMMM yyyy"
                      // value={isNew ? state.today : convertDateTimeFromServer(datadetail['expDate']).format('YYYY-MM-DDThh:mm:ss')}
                      disabled={true}
                      value={postDate}
                    />
                  </div>
                </div>
            </div>
        </div>
      </div> */}

      <div className="bg-white rounded px-3 py-4 shadow my-4">
        <div className="row mb-3">
          <div className="font-family-spoqa col-1">
            To
          </div>
          <div className="font-family-spoqa col-11">
            <MultiSelect
              overrideStrings={{
                allItemsAreSelected: "All Divisions/Teams are Selected",
                selectAll: "Select All Divisions/Teams",
                search: "Search",
              }}
              options={listTo}
              value={to}
              onChange={setTo}
              labelledBy="Select"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="font-family-spoqa col-1">
            Title
          </div>
          <div className="col-11">
            <input type="text" value={title} className="font-family-spoqa form-control" onChange={e => {
              let string = limitString(e.target.value, 250)
              setTile(string)
            }
            } />
          </div>
        </div>
      </div>

      <div className="bg-white rounded px-3 py-4 shadow my-4" style={{ zIndex: 0 }}>
        <Editor
          apiKey={process.env.REACT_APP_TYMCE_APIKEY}
          onInit={(evt, editor) => editorRef.current = editor}
          // initialValue={isNew ? null : datadetail['fileContent']}
          // initialValue="as,daslkdjas"
          onChange={data => setFileContent(data.target.getContent())}
          // onSubmit={handleEditorSubmit}
          // onSubmit={saveEntity}
          // disabled={disabledDocument}
          init={{
            height: 800,
            menubar: true,
            paste_data_images: true,
            // paste_block_drop: true,
            forced_root_block_attrs: {
              'style': 'font-size: 10pt;'
            },
            forced_root_block: 'div',
            force_br_newlines: true,
            force_p_newlines: false,
            force_div_newlines: false,
            formats: {
              bold: { inline: 'b' },
              italic: { inline: 'i' },
              underline: { inline: 'u' }
            },
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount hr','powerpaste'
            ],
            toolbar: 'undo redo | fontsizeselect | code |  formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              ' help hr',
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12pt }'
          }}
        />
      </div>

      <div className="bg-white rounded px-3 py-4 shadow my-4">
        <h5>
          Upload File
        </h5>

        <div>
          <div className="my-3">
            {
              urlImage ? <div style={{ height: 500, position: 'relative' }}> <img id="target" className="shadow" src={urlImage} style={{
                width: '100%',
                height: '100%',
                borderRadius: 12,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }} />
                <CloseDeleteIcon style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  cursor: 'pointer'
                }} onClick={() => {
                  setUrlImage(null)
                  setFiles(null)
                }} />
              </div> : <></>
            }
          </div>
          {
            !urlImage ? <>
              <div style={{ marginTop: 20 }}></div>
              <label htmlFor={"file_picker"} id="drop_zone" onDrop={onDropHandler} onDragOver={onDragOver}>
                <DragUploadIcon />
                <div>Drag and drop a file here</div>
                <input
                  id="file_picker"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(ev) => {
                    handlerChangeImage(ev.target.files[0])
                  }}
                  style={{ display: "none" }}
                ></input>
              </label>
            </> : <></>
          }
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-6">

          </div>
          <div className="col-6 text-right">
            <button
              disabled={
                !fileContent || !title || loadingCreate
              }
              className="btn btn-primary mx-1" onClick={(e) => saveDraft(e)}>
              {loadingCreate ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}Save
            </button>
            <button
              disabled={
                !fileContent || !title || loadingCreate
              }
              className="btn btn-primary mx-1" onClick={(e) => saveAndPublish(e)}>
              {loadingCreate ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>} Save & Publish
            </button>
          </div>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = ({ document }: IRootState) => ({

});


const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(AnnoucementCreate);
