import {IRootState} from "app/shared/reducers";
import {logout} from "app/shared/reducers/authentication";
import {connect} from "react-redux";
import {ProfilePage} from "app/modules/profile/profile";
import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalBody, Spinner} from "reactstrap";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row, Col} from 'react-bootstrap';
import SuccessIllustrationHint from "app/component/success-illustration-hint";
import Axios from "axios";
import {toast} from "react-toastify";
import {translate} from "react-jhipster";

export const ChangePasswordProfile = () => {

    const history = useHistory()

    //Form Handle Start
    const icPassEye = "fa fa-fw fa-eye field-icon";
    const icSlashPassEye = "fa fa-eye fa-eye-slash";

    const [getIcTogglePassword, setIcTogglePassword] = useState(icSlashPassEye);
    const [getIcToggleNewPassword, setIcToggleNewPassword] = useState(icSlashPassEye);
    const [getIcToggleConfirmPassword, setIcToggleConfirmPassword] = useState(icSlashPassEye);

    const [getTypeTogglePassword, setTypeTogglePassword] = useState("password");
    const [getTypeToggleNewPassword, setTypeToggleNewPassword] = useState("password");
    const [getTypeToggleConfirmPassword, setTypeToggleConfirmPassword] = useState("password");

    const [etPassword, setPassword] = useState("")
    const [etNewPassword, setNewPassword] = useState("")
    const [etPasswordConf, setPasswordConf] = useState("")
    const [loadingButton, setLoadingButton] = useState<boolean>(true)
    const [loadingSpinButton, setLoadingSpinButton] = useState<boolean>(false)
    const [validationStatus, setValidationStatus] = useState({
        length: false,
        case: false,
        noSpecialChar: false,
        noSpace: false,
        match: false,
        number: false
    });
    const togglePass = () => {
        if (getIcTogglePassword == icSlashPassEye) {
            setIcTogglePassword(icPassEye);
            setTypeTogglePassword("text");
        } else {
            setIcTogglePassword(icSlashPassEye);
            setTypeTogglePassword("password");
        }
    }
    const toggleNewPass = () => {
        if (getIcToggleNewPassword == icSlashPassEye) {
            setIcToggleNewPassword(icPassEye);
            setTypeToggleNewPassword("text");
        } else {
            setIcToggleNewPassword(icSlashPassEye);
            setTypeToggleNewPassword("password");
        }
    }
    const toggleConfPass = () => {
        if (getIcToggleConfirmPassword == icSlashPassEye) {
            setIcToggleConfirmPassword(icPassEye);
            setTypeToggleConfirmPassword("text");
        } else {
            setIcToggleConfirmPassword(icSlashPassEye);
            setTypeToggleConfirmPassword("password");
        }
    }
    const validation = (valueInput) => {
        var reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9]).{8,12}$');
        return reg.test(valueInput)
    }
    const validatePassword = (password, confirmPassword) => {
        const notEmpty = password.trim() !== "" && confirmPassword.trim() !== ""; // Check if neither field is empty

        const lengthValid = password.length >= 8 && password.length <= 12;
        const caseValid = /[A-Z]/.test(password) && /[a-z]/.test(password)
        const noSpecialCharValid =notEmpty &&  /^[A-Za-z0-9 ]+$/.test(password) &&  /^[A-Za-z0-9 ]+$/.test(confirmPassword);
        const noSpaceValid =notEmpty && !/\s/.test(password) &&  !/\s/.test(confirmPassword);
        const matchValid = notEmpty && password === confirmPassword;
        const numberValid = /\d/.test(password); // Ensure password contains at least one number
        const allValid = lengthValid && caseValid && noSpecialCharValid && noSpaceValid && numberValid && matchValid;

        setValidationStatus({
            length: lengthValid,
            case: caseValid,
            noSpecialChar: noSpecialCharValid,
            noSpace: noSpaceValid,
            match: matchValid,
            number: numberValid

        });
        if (allValid) {
            setLoadingButton(false);
        }
        else{
            setLoadingButton(true)
        }
    };

    const containsSpecialChars = (str) => {
        const specialChars =
            /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    const getValidationStyle = (isValid) => ({
        color: isValid ? "green" : "red",
        alignItems: "center", // Aligns icon and text vertically
        fontWeight: isValid ? "bold" : "normal",
        gap: "8px",
        marginTop: "5px",
        display:"block"
    });


    const getFontShadowStyle = (isValid) => ({
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px", // Adjust size as needed
        height: "20px",
        borderRadius: "50%",
        backgroundColor: isValid ? "green" : "red",
        color: "white",
        textShadow: isValid ? "1px 1px 2px green" : "1px 1px 2px red",
        fontWeight: "bold",
        marginRight: "10px", // Add spacing between icon and text
    });

    const handleOldPasswordChange = (e) => {
        const newValue = e.target.value.replace(/\s/g, '');
        setPassword(newValue);
    };

    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        validatePassword(value, etPasswordConf);
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setPasswordConf(value);
        validatePassword(etNewPassword, value);
    };
    const handleValidateChangePassword = () => {

        if (etPassword == "" || etNewPassword == "" || etPasswordConf == "") {
            toast.error("Please fill in all fields.");
        } else if (!validation(etNewPassword) || !validation(etPasswordConf)) {
            toast.error("Password must meet all requirements (e.g., length, case, no special characters).");
        } else if (etNewPassword != etPasswordConf) {
            toast.error("New password and confirm password do not match.");
        } else if (containsSpecialChars(etNewPassword) || containsSpecialChars(etPasswordConf)) {
            toast.error("Password should not contain special characters.");
        }else if (!/\d/.test(etNewPassword) || !/\d/.test(etPasswordConf)) {
                toast.error("Your password doesn't have a number");
        } else {

            setLoadingButton(true)
            setLoadingSpinButton(true)
            Axios.get('services/uaadocservice/api/account')
                .then(resAcc => {
                    Axios.post('/services/uaadocservice/api/employee/change-password',
                        {
                            "userName": resAcc.data.employeeNo,
                            "currentPassword": etPassword,
                            "newPassword": etNewPassword,
                            "confirmNewPassword": etPasswordConf
                        }).then(res => {
                        setLoadingButton(false)
                        if(res.data.code == 200) {
                            history.replace('/profile/me')
                            toast.success(`Change Password Successfull`, {
                                position: "top-left",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast.error(`${res.data.message}`, {
                                position: "top-left",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }).catch(err => {
                        setLoadingButton(false)
                        setLoadingSpinButton(false)
                        console.log("Response catch",err)
                        const errorMessage = `${err.response.data.message}` == "error.errorhotswrservice.message.error1" ? "error.errorhotswrservice.message.error11":`${err.response.data.message}`;
                          toast.error(translate(errorMessage), {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }).catch(err => {
                        setLoadingButton(false)
                        setLoadingSpinButton(false)
                        toast.error(translate(`${err.response.data.message}`), {
                            position: "top-left",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                })
        }
    }

    const [getModalTitleMessage, setModalTitleMessage] = useState("");
    const [getModalDescMessage, setModalDescMessage] = useState("");
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);

    const [isDisabledButton, setDisabledButton] = useState(false);

    //Form Handle End

    return <div className="container">
        <div className='container d-flex  h-100' style={{alignItems: 'center', justifyContent: 'center'}}>

            <div className="font-family-spoqa" style={{padding: '10px', width: '70%'}}>
                <div className='d-flex'>
                    <button className="btn px-0 mr-4" onClick={() => history.replace("/profile/me")}>
                        <FontAwesomeIcon size="2x" icon="arrow-left" color="#495057"/>{' '}
                    </button>
                    <h2 className='mb-0'>
                        Change Password
                    </h2>
                </div>

                <div style={{padding: '10px', width: '70%'}}>

                    {/*Old Password Start*/}
                    <div className="row">
                        <div>
                            <h5 font-family-spoqa style={{marginTop: '50px'}}>
                                Old Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etPassword} name="etNewPass" minLength={8} maxLength={12}
                                       style={{width: "100%"}}
                                       type={getTypeTogglePassword} placeholder="Input Old Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           handleOldPasswordChange(e)
                                       }
                                       }/>
                            </Col>
                        </Row>
                        <Col onClick={togglePass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcTogglePassword}></span>
                        </Col>

                    </div>
                    {/*Old Password End*/}

                    {/*New Password Start*/}
                    <div className="row">
                        <div>
                            <h5 style={{marginTop: '24px'}}>
                                New Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etNewPassword} name="etNewPass" minLength={8} maxLength={12}
                                       style={{width: "100%"}}
                                       type={getTypeToggleNewPassword} placeholder="Input New Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           handleNewPasswordChange(e)
                                       }
                                       }/>
                            </Col>
                        </Row>
                        <Col onClick={toggleNewPass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcToggleNewPassword}></span>
                        </Col>
                    </div>
                    {/*New Password End*/}

                    {/*Confirmation Password Start*/}
                    <div className="row mb-4">
                        <div>
                            <h5 style={{marginTop: '24px'}}>
                                Confirm New Password
                            </h5>
                        </div>
                        <Row style={{marginBottom: "4px", paddingRight: 0}}>
                            <Col style={{paddingRight: 0}}>
                                <input value={etPasswordConf} name="etNewPass" minLength={8} maxLength={12}
                                       style={{width: "100%"}}
                                       type={getTypeToggleConfirmPassword} placeholder="Confirm New Password"
                                       className="form-control"
                                       onChange={(e) => {
                                           handleConfirmPasswordChange(e)
                                       }}/>
                            </Col>
                        </Row>
                        <Col onClick={toggleConfPass}
                             style={{
                                 cursor: "pointer",
                                 maxWidth: "10px",
                                 marginTop: "8px",
                                 marginLeft: "-24px",
                                 paddingRight: 0
                             }}>
                            <span className={getIcToggleConfirmPassword}></span>
                        </Col>
                    </div>
                    {/*Confirmation Password End*/}

                    {/*<div style={{textAlign: "justify", lineHeight: "2.0"}}>*/}
                    {/*    <ul style={{marginTop: "-10px"}}>*/}
                    {/*        <li>Password must be at least min 8 characters max 12 characters</li>*/}
                    {/*        <li>Must include at least one upper case letter, one lower case letter, and one numeric*/}
                    {/*            digit*/}
                    {/*        </li>*/}
                    {/*        <li>Password doesn't include special character</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className="div-container-column mt-4">
                        <ul style={{marginTop: "-10px"}}>
                            <li style={getValidationStyle(validationStatus.length)}>
                                <span
                                    style={getFontShadowStyle(validationStatus.length)}>{validationStatus.length ? "✔" : "✘"}</span>
                                Password must be at least 8 characters and max 12 characters
                            </li>
                            <li style={getValidationStyle(validationStatus.case)}>
                                 <span
                                     style={getFontShadowStyle(validationStatus.case)}>{validationStatus.case ? "✔" : "✘"}</span>
                                Must include at least one upper case letter, one lower case
                                letter
                            </li>
                            <li style={getValidationStyle(validationStatus.noSpecialChar)}>
                                 <span
                                     style={getFontShadowStyle(validationStatus.noSpecialChar)}>{validationStatus.noSpecialChar ? "✔" : "✘"}</span>
                                Password doesn't include special character
                            </li>
                            <li style={getValidationStyle(validationStatus.match)}>
                                <span
                                    style={getFontShadowStyle(validationStatus.match)}>{validationStatus.match ? "✔" : "✘"}</span>
                                New password and confirm password must match
                            </li>
                            <li style={getValidationStyle(validationStatus.noSpace)}>
                                 <span
                                     style={getFontShadowStyle(validationStatus.noSpace)}>{validationStatus.noSpace ? "✔" : "✘"}</span>
                                Your input contains spaces. Please remove any spaces and try again.
                            </li>
                            <li style={getValidationStyle(validationStatus.number)}>
                                <span
                                                            style={getFontShadowStyle(validationStatus.number)}>{validationStatus.number ? "✔" : "✘"}</span>
                                Your password doesn't have a number.
                            </li>
                        </ul>
                    </div>

                    <Button disabled={loadingButton}
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                                handleValidateChangePassword()
                            }}
                            style={loadingButton ? {
                                backgroundColor: '#9f9f9f',
                                width: '100%',
                            } : {backgroundColor: '#F37F26', width: '100%'}}>
                        {(loadingSpinButton) ? <Spinner color='#fff' size="sm" style={{marginRight: "8px"}}/> : <></>}
                        Save New Password
                    </Button>

                </div>

            </div>
        </div>
    </div>
}

export default ChangePasswordProfile;