import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import ErrorMessage from "app/component/error-message/ErrorMessage";
import {ValidateImageUpload} from "app/shared/util/image-utils";

const payloadFormData = {
    menuEN: "",
    menuID: "",
    subMenuEN: "",
    subMenuID: "",
    htmlContentEN: "",
    htmlContentID: "",
}

const payloadErrorData = {
    menuEN: "",
    menuID: "",
    subMenuEN: "",
    subMenuID: "",
    htmlContentEN: "",
    htmlContentID: "",
}

const ContentEdit: React.FC = () => {
    let history = useHistory();
    const [formData, setFormData] = useState(payloadFormData)

    const [isLoading, setLoading] = useState(false)

    const [errors, setErrors] = useState(payloadErrorData);

    const onBackPressed = () => {
        setErrors({
            ...errors,
            menuEN: '',
            menuID: '',
            subMenuEN: '',
            subMenuID: '',
            htmlContentEN: '',
            htmlContentID: '',
        })
        history.push(`/corporate/content`);
    };

    const errorSubmit = {...errors}

    const ButtonActive = {
        EN: true,
        ID: false
    }

    const [isClicked, setClicked] = useState(ButtonActive)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.menuEN) {
            errors.menuEN = 'Menu EN is required';
        }

        if (!formData.menuID) {
            errors.menuID = 'Menu ID wajib diisi';
        }

        if (!formData.subMenuEN) {
            errors.subMenuEN = 'Sub Menu EN is required';
        }

        if (!formData.subMenuID) {
            errors.subMenuID = 'Sub Menu ID wajib diisi';
        }

        if (!formData.htmlContentEN) {
            errors.htmlContentEN = 'HTML Content EN is required';
        }

        if (!formData.htmlContentID) {
            errors.htmlContentID = 'HTML Content ID wajib diisi';
        }

        setErrors(errors);

        handleSubmit(formData);

    };

    const handleSubmit = async (payload: any) => {

        payload.menuEN = formData.menuEN;
        payload.menuID = formData.menuID;
        payload.subMenuEN = formData.subMenuEN;
        payload.subMenuID = formData.subMenuID;
        payload.htmlContentEN = formData.htmlContentEN;
        payload.htmlContentID = formData.htmlContentID;

        console.log("MASI PAYLOAD ",payload)

    };

    const menuOption = {
        EN: [
            {value: 'Option 1', label: 'Menu EN 1'},
            {value: 'Option 2', label: 'Menu EN 2'},
            {value: 'Option 3', label: 'Menu EN 3'},
        ],
        ID: [
            {value: 'Opsi 1', label: 'Pilihan ID 1'},
            {value: 'Opsi 2', label: 'Pilihan ID 2'},
            {value: 'Opsi 3', label: 'Pilihan ID 3'},
        ],
    }

    const subMenuOptoion = {
        EN: [
            {value: 'Sub Option 1', label: 'Sub Menu EN 1'},
            {value: 'Sub Option 2', label: 'Sub Menu EN 2'},
            {value: 'Sub Option 3', label: 'Sub Menu EN 3'},
        ],
        ID: [
            {value: 'Sub Opsi 1', label: 'Sub Pilihan ID 1'},
            {value: 'Sub Opsi 2', label: 'Sub Pilihan ID 2'},
            {value: 'Sub Opsi 3', label: 'Sub Pilihan ID 3'},
        ],
    }

    const onFieldChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>, field: string) => {
        console.log('MASI onFieldChange', field, e.target.value);
        if (e.target.value) {
            setErrors({ ...errors, [field]: '' });
        }

        setFormData({ ...formData, [field]: e.target.value });
    }

    return (
        <form className="container" onSubmit={onSubmit} style={formStyle}>
            <div>
                <div className="col-16">
                    <button className="btn px-0 mb-2" onClick={onBackPressed}>
                        <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                            <FontAwesomeIcon icon="arrow-left" color="black"/>
                        </div>
                    </button>
                </div>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <div style={{marginRight: '10px', display: 'none'}}>
                        <img src="./content/images/file_image_banner.svg" alt="Image"
                             style={{width: '50px', height: '50px'}}/>
                    </div>
                    <div>
                        <h2 style={headerStyle}>Edit Content</h2>
                    </div>
                </div>

                {/*Button EN / ID*/}
                <div style={modalStyles.buttonGroup}>
                    <button style={isClicked.EN ? modalStyles.buttonEN : modalStyles.buttonInactiveEN} onClick={(clicked) => {
                        clicked.preventDefault()
                        // setIsModalOpen(true)
                        toast.info("Set Content Input in English", {autoClose: 3000})
                        setClicked({EN: true, ID: false})
                    }}>
                        EN
                    </button>
                    <button style={isClicked.ID ? modalStyles.buttonID : modalStyles.buttonInactiveID} onClick={(clicked) => {
                        clicked.preventDefault()
                        // setIsModalOpen(true)
                        toast.info("Mengatur masukan konten dalam Bahasa Indonesia", {autoClose: 3000})
                        setClicked({EN: false, ID: true})

                    }}>
                        ID
                    </button>
                </div>
                <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>
            </div>

            <hr style={{border: '0.5px solid lightgray', width: '100%', display: 'none'}}/>

            {/* Main Menu */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Main Menu</label>
                <select
                    value={isClicked.EN ? formData.menuEN : formData.menuID}
                    onChange={(e)=> onFieldChange(e, isClicked.EN ? 'menuEN' : 'menuID')}
                    style={inputStyle}>
                    {
                        isClicked.EN ?
                            <option value="">Select Menu Option</option>
                            : <option value="">Pilih Opsi Menu</option>}
                    {
                        menuOption[isClicked.EN ? "EN" : "ID"].map((option) => (
                            <option
                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}

                </select>
                <ErrorMessage message={errors[isClicked.EN ? 'menuEN' : 'menuID']}/>
            </div>

            {/* Sub Menu */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Sub Menu</label>
                <select
                    value={isClicked.EN ? formData.subMenuEN : formData.subMenuID}
                    onChange={(e)=> onFieldChange(e, isClicked.EN ? 'subMenuEN' : 'subMenuID')}
                    style={inputStyle}
                >
                    {
                        isClicked.EN ?
                            <option value="">Select Sub Menu Option</option>
                            : <option value="">Pilih Sub Opsi Menu</option>}
                    {
                        subMenuOptoion[isClicked.EN ? "EN" : "ID"].map((option) => (
                            <option
                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </select>
                <ErrorMessage message={errors[isClicked.EN ? 'subMenuEN' : 'subMenuID']}/>
            </div>

            {/* HTML Content */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>HTML Content</label>
                <textarea
                    value={isClicked.EN ? formData.htmlContentEN : formData.htmlContentID}
                    onChange={(e)=> onFieldChange(e,  isClicked.EN ? 'htmlContentEN' : 'htmlContentID')}
                    style={inputDescriptionStyle}
                />
                <ErrorMessage message={errors[isClicked.EN ? 'htmlContentEN' : 'htmlContentID']}/>
            </div>

            {/* Save Button */}
            <button
                type="submit"
                style={isLoading ? buttonProcess : buttonStyle}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div style={{alignItems: "center"}}>
                        Submitting...
                    </div>
                ) : (
                    "Submit"
                )}
            </button>

            {isModalOpen && (
                <div style={modalStyles.overlay}>
                    <div style={modalStyles.modal}>
                        <div style={modalStyles.icon}>
              <span role="img" aria-label="Warning">
                <img src={'./content/images/icon-alert.svg'} alt="Alert Icon" style={{width: '32px', height: '32px'}}/>
              </span>
                        </div>
                        {
                            isClicked.ID ? <h2>Informasi</h2> : <h2>Information</h2>
                        }

                        {
                            isClicked.ID ? <p>Mengatur masukan konten dalam Bahasa Indonesia </p> :
                                <p> Set Content Input in English </p>
                        }
                        <div>
                            <button style={{...modalStyles.buttonConfirmation, textAlign: 'center'}} onClick={() => {
                                // setIsModalOpen(false)
                            }}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer'
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

const inputDescriptionStyle: React.CSSProperties = {
    width: '100%',
    height: '300px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    caretColor: 'auto', // or any other color you want
    boxSizing: 'border-box',
    overflowY: 'hidden',
    verticalAlign: 'top',
};

const dateFieldContainer: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

const dateFieldError: React.CSSProperties = {
    position: 'relative',
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const buttonProcess: React.CSSProperties = {
    backgroundColor: 'lightgray', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const btnRemoveStyle: React.CSSProperties = {
    position: 'absolute',
    transform: 'translateY(-50%)',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'red',
    fontStyle: 'bold',
    top: '50%',
    right: '8%',
}

const modalStyles = {
    overlay: {
        position: "fixed" as "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modal: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "20px",
        width: "400px",
        textAlign: "center" as "center",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    icon: {
        fontSize: "30px",
        color: "red",
        marginBottom: "10px",
    },
    buttonGroup: {
        display: "flex",
        marginTop: "4px",
    },
    buttonConfirmation: {
        backgroundColor: "#F58220",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        width: "50%",
        height: "40px"
    },
    buttonEN: {
        backgroundColor: '#F58220',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonInactiveEN: {
        backgroundColor: 'lightgray',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonID: {
        backgroundColor: '#F58220',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonInactiveID: {
        backgroundColor: 'lightgray',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
};

export default ContentEdit;
