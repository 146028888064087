import React, { useState, useEffect } from "react";
import EditBannerForm from "app/entities/banner/main-menu/edit-banner/EditBannerForm";
import EditPreview from "app/entities/banner/main-menu/edit-banner/EditPreview";
import { useParams } from "react-router-dom";
import { getBannerById } from "app/entities/banner/services/BannerServices";
import { BannerItemModel, DataItem } from "app/entities/banner/model/BannerItemModel";
import {toast} from "react-toastify";
import useNetworkStatus from "app/utils/NetworkStatus";

export const EditBannerContent: React.FC = () => {
    const [editImgPreview, setEditImgPreview] = useState<string | null>(null);
    const [itemById, setItemById] = useState<DataItem>({
        id: '',
        type: '',
        filename: '',
        dateFrom: '',
        dateTo: '',
        urlRedirect: '',
        status: '',
        created_date: '',
        created_by: '',
        preview: ''
    });

    const { id } = useParams();

    const { isOnline } = useNetworkStatus();

    useEffect(() => {
        fetchBannerById();
    }, []);

    useEffect(() => {
        if (!isOnline) {
            toast.error('You are offline. Please check your internet connection.', { autoClose: 3000 });
        }
    }, [isOnline]);

    const handleEditImage = (file: File | null) => {
        try {
            if (file) {
                const reader = new FileReader();
                reader.abort();
                reader.onload = (e) => setEditImgPreview(e.target?.result as string);
                reader.readAsDataURL(file);
            } else {
                setEditImgPreview(null);
            }
        } catch (error) {
            throw error;
        }
    };

    const handleClearFileName = () => {
        setEditImgPreview(null);
        setItemById({...itemById, preview : null, filename : ""})
    };

    const handleClearImage = () => {
        setEditImgPreview(null);

        const fileInput = document.getElementById("file-input") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";  // Ensures the same file can be uploaded again
        }

    };

    const fetchBannerById = async () => {
        try {
            const response = await getBannerById(id);
            setItemById({ ...response.data });
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="container" style={{ display: 'flex', gap: '10px', padding: '10px' }}>
            <div style={{ flex: 1 }}>
                {itemById && (
                    <EditBannerForm
                        onImageUpdate={handleEditImage}
                        dataBanner={itemById}
                        // fileName={fileName}
                        onClearFileName={handleClearFileName}
                    />
                )}
            </div>
            <div style={{ flex: 1 }}>
                <EditPreview
                    imageUrl={editImgPreview || undefined}
                    dataImage={ itemById.preview || undefined }
                    onClearImage={handleClearImage}
                />
            </div>
        </div>
    );
};
