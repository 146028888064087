// image-utils.ts
export const ValidateImageUpload = (file: File): { valid: boolean; message: string } => {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (!acceptedImageTypes.includes(file.type)) {
        return { valid: false, message: 'Only images are allowed' };
    }
    if (file.size > maxSize) {
        return { valid: false, message: 'Image size exceeds 2MB' };
    }
    return { valid: true, message: '' };
};
