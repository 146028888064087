import React, {useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import { BannerTypeList} from "app/entities/banner/model/BannerListModel";


interface BannerTableProps {
    banners: BannerTypeList[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const ContentTable: React.FC<BannerTableProps> = ({ banners, onEdit, onDelete }) => {

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const onPreview = (image: string) => {
        // Show the preview image
        setImagePreview(image);
        // You can also add other logic here, such as opening a modal or showing a larger preview
    };

    const handleClosePreview = () => {
        setImagePreview(null);
    };

    return (
        <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={titleVerticalDivider}>ID</TableCell>
                        <TableCell style={titleVerticalDivider}>Main Menu</TableCell>
                        <TableCell style={titleVerticalDivider}>Sub Menu</TableCell>
                        <TableCell style={titleVerticalDivider}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {banners.map((banner, index) => (
                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F5FCFF' : 'white' }}>
                            <TableCell style={verticalDivider}>
                                <div style={{color:'black', fontWeight: 'bold'}}>
                                    {banner.created_by}
                                </div>
                            </TableCell>
                            <TableCell style={verticalDivider}>
                                <div className="font-size-14" style={{ color: '#636566' }}>
                                    {banner.dateFrom } - { banner.dateTo }
                                </div>
                            </TableCell>
                            <TableCell style={verticalDivider}>
                                <div style={{textAlign: 'justify'}}>
                                    {banner.urlRedirect}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div style={{textAlign: 'center'}}>
                                    <button className='btn btn-danger' type="submit" style={{marginRight: '8px', width : '70px', cursor: 'pointer'}}
                                            onClick={(event) => onDelete(banner.id)}>
                                        Delete
                                    </button>
                                    <button className='btn btn-info' type="submit" style={{marginRight: '8px', width : '70px', cursor: 'pointer'}}
                                            onClick={() => onEdit(banner.id)}>
                                        Update
                                    </button>
                                    <button className='btn btn-primary' type="submit" style={{width : '70px', cursor: 'pointer'}}
                                            onClick={(event) => onPreview(banner.preview)}>
                                        View
                                    </button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const verticalDivider: React.CSSProperties = {
    borderRight: '1px solid #ccc'
};

const titleVerticalDivider: React.CSSProperties = {
    borderRight: '1px solid #ccc',
    fontWeight: "bold",
    textAlign: 'center'
};


export default ContentTable;
