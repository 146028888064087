import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import ErrorMessage from "app/component/error-message/ErrorMessage";
import {ValidateImageUpload} from "app/shared/util/image-utils";

const payloadFormData = {
    positionEN: "",
    positionID: "",
    mainMenuEN: "",
    mainMenuID: "",
    menuTitleEN: "",
    menuTitleID: "",
    targetURL:"",
    checkboxURL: false
}

const payloadErrorData = {
    positionEN: "",
    positionID: "",
    mainMenuEN: "",
    mainMenuID: "",
    menuTitleEN: "",
    menuTitleID: "",
    targetURL:"",
    checkboxURL: false
}

const MenuEdit: React.FC = () => {
    let history = useHistory();
    const [formData, setFormData] = useState(payloadFormData)

    const [isLoading, setLoading] = useState(false)

    const [errors, setErrors] = useState(payloadErrorData);

    const onBackPressed = () => {
        setErrors({
            ...errors,
            positionEN: "",
            positionID: "",
            mainMenuEN: "",
            mainMenuID: "",
            menuTitleEN: "",
            menuTitleID: "",
            targetURL:"",
            checkboxURL: false
        })
        history.push(`/corporate/menu`);
    };

    const errorSubmit = {...errors}

    const ButtonActive = {
        EN: true,
        ID: false
    }

    const [isClicked, setClicked] = useState(ButtonActive)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.positionEN) {
            errors.positionEN = 'Menu EN is required';
        }

        if (!formData.positionID) {
            errors.positionID = 'Menu ID wajib diisi';
        }

        if (!formData.mainMenuEN) {
            errors.mainMenuEN = 'Sub Menu EN is required';
        }

        if (!formData.mainMenuID) {
            errors.mainMenuID = 'Sub Menu ID wajib diisi';
        }

        if (!formData.menuTitleEN) {
            errors.menuTitleEN = 'HTML Content EN is required';
        }

        if (!formData.menuTitleID) {
            errors.menuTitleID = 'HTML Content ID wajib diisi';
        }

        setErrors(errors);

        handleSubmit(formData);

    };

    const handleSubmit = async (payload: any) => {

        payload.positionEN = formData.positionEN;
        payload.positionID = formData.positionID;
        payload.mainMenuEN = formData.mainMenuEN;
        payload.mainMenuID = formData.mainMenuID;
        payload.menuTitleEN = formData.menuTitleEN;
        payload.menuTitleID = formData.menuTitleID;

        console.log("MASI PAYLOAD ",payload)

    };

    const menuOption = {
        EN: [
            {value: 'Position 1', label: 'Position EN 1'},
            {value: 'Position 2', label: 'Position EN 2'},
            {value: 'Position 3', label: 'Position EN 3'},
        ],
        ID: [
            {value: 'Posisi 1', label: 'Posisi ID 1'},
            {value: 'Opsi 2', label: 'Posisi ID 2'},
            {value: 'Opsi 3', label: 'Posisi ID 3'},
        ],
    }

    const subMenuOptoion = {
        EN: [
            {value: 'Main Menu 1', label: 'Main Menu EN 1'},
            {value: 'Main Menu 2', label: 'Main Menu EN 2'},
            {value: 'Main Menu 3', label: 'Main Menu EN 3'},
        ],
        ID: [
            {value: 'Menu Utama 1', label: 'Menu Utama ID 1'},
            {value: 'Menu Utama 2', label: 'Menu Utama ID 2'},
            {value: 'Menu Utama 3', label: 'Menu Utama ID 3'},
        ],
    }

    const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, field: string) => {
        console.log('MASI onFieldChange', field, e.target.value);
        if (e.target.value) {
            setErrors({ ...errors, [field]: '' });
        }

        setFormData({ ...formData, [field]: e.target.value });
    }

    useEffect(() => {
        if (history.length > 0){
            setErrors({
                ...payloadErrorData,
                positionEN: "",
                positionID: "",
                mainMenuEN: "",
                mainMenuID: "",
                menuTitleEN: "",
                menuTitleID: "",
                targetURL:"",
                checkboxURL: false
            })
            console.log("MASI CEK ERROR BACK ", errors)
        }

    }, [history]);

    return (
        <form className="container" onSubmit={onSubmit} style={formStyle}>
            <div>
                <div className="col-16">
                    <button className="btn px-0 mb-2" onClick={onBackPressed}>
                        <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                            <FontAwesomeIcon icon="arrow-left" color="black"/>
                        </div>
                    </button>
                </div>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <div style={{marginRight: '10px', display: 'none'}}>
                        <img src="./content/images/file_image_banner.svg" alt="Image"
                             style={{width: '50px', height: '50px'}}/>
                    </div>
                    <div>
                        <h2 style={headerStyle}>Edit Menu</h2>
                    </div>
                </div>

                {/*Button EN / ID*/}
                <div style={modalStyles.buttonGroup}>
                    <button style={isClicked.EN ? modalStyles.buttonEN : modalStyles.buttonInactiveEN} onClick={(clicked) => {
                        clicked.preventDefault()
                        // setIsModalOpen(true)
                        toast.info("Set Content Input in English", {autoClose: 3000})
                        setClicked({EN: true, ID: false})
                    }}>
                        EN
                    </button>
                    <button style={isClicked.ID ? modalStyles.buttonID : modalStyles.buttonInactiveID} onClick={(clicked) => {
                        clicked.preventDefault()
                        // setIsModalOpen(true)
                        toast.info("Mengatur masukan konten dalam Bahasa Indonesia", {autoClose: 3000})
                        setClicked({EN: false, ID: true})

                    }}>
                        ID
                    </button>
                </div>
                <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>
            </div>

            <hr style={{border: '0.5px solid lightgray', width: '100%', display: 'none'}}/>

            {/* Position */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Position</label>
                <select
                    value={isClicked.EN ? formData.positionEN : formData.positionID}
                    onChange={(e)=> onFieldChange(e, isClicked.EN ? 'positionEN' : 'positionID')}
                    style={inputStyle}>
                    {
                        isClicked.EN ?
                            <option value="">Select Position</option>
                            : <option value="">Pilih Posisi</option>}
                    {
                        menuOption[isClicked.EN ? "EN" : "ID"].map((option) => (
                            <option
                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}

                </select>
                <ErrorMessage message={errors[isClicked.EN ? 'positionEN' : 'positionID']}/>
            </div>

            {/* Main Menu */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Main Menu</label>
                <select
                    value={isClicked.EN ? formData.mainMenuEN : formData.mainMenuID}
                    onChange={(e)=> onFieldChange(e, isClicked.EN ? 'mainMenuEN' : 'mainMenuID')}
                    style={inputStyle}>
                    {
                        isClicked.EN ?
                            <option value="">Select Main Menu</option>
                            : <option value="">Pilih Menu Utama</option>}
                    {
                        menuOption[isClicked.EN ? "EN" : "ID"].map((option) => (
                            <option
                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}

                </select>
                <ErrorMessage message={errors[isClicked.EN ? 'mainMenuEN' : 'mainMenuID']}/>
            </div>

            {/* Menu Title */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Menu Title</label>
                <select
                    value={isClicked.EN ? formData.menuTitleEN : formData.menuTitleID}
                    onChange={(e)=> onFieldChange(e, isClicked.EN ? 'menuTitleEN' : 'menuTitleID')}
                    style={inputStyle}>
                    {
                        isClicked.EN ?
                            <option value="">Select Menu Title</option>
                            : <option value="">Pilih Judul Menu</option>}
                    {
                        menuOption[isClicked.EN ? "EN" : "ID"].map((option) => (
                            <option
                                key={option.value}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}

                </select>
                <ErrorMessage message={errors[isClicked.EN ? 'menuTitleEN' : 'menuTitleID']}/>
            </div>

            {/* Target URL */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target URL</label>
                <input
                    type="text"
                    value={formData.targetURL}
                    onChange={(e) => {
                        onFieldChange(e, 'targetURL')
                    }}
                    style={{
                        ...inputStyle,
                        cursor: !formData.checkboxURL ? 'not-allowed' : 'pointer',
                        borderColor: formData.checkboxURL ? '#f97316' : 'lightgray',
                        transition: "background-color 0.3s ease",
                    }}
                    disabled={!formData.checkboxURL}
                    onFocus={(e) => {
                        e.target.style.backgroundColor = "#f97316";
                    }}
                    onBlur={(e) => {
                        e.target.style.backgroundColor = "lightgray";
                    }}
                />

                <div
                    style={{
                        display: "inline-block",
                        position: "relative",
                        width: "20px",
                        height: "20px",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={formData.checkboxURL}
                        onChange={(e) => {
                            setFormData({ ...formData, checkboxURL: e.target.checked });
                        }}
                        style={{
                            ...checkBoxStyle,
                            backgroundColor: formData.checkboxURL ? "#F58220" : "white",
                        }}
                    />
                    {formData.checkboxURL && (
                        <span
                            style={checkBoxSpanStyle}>
                            ✔
                        </span>
                    )}
                </div>

                <ErrorMessage message={errors[isClicked.EN ? 'targetURLEN' : 'targetURLID']}/>
            </div>

            {/* Save Button */}
            <button
                type="submit"
                style={isLoading ? buttonProcess : buttonStyle}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div style={{alignItems: "center"}}>
                        Updating...
                    </div>
                ) : (
                    "Update"
                )}
            </button>

            {isModalOpen && (
                <div style={modalStyles.overlay}>
                    <div style={modalStyles.modal}>
                        <div style={modalStyles.icon}>
              <span role="img" aria-label="Warning">
                <img src={'./content/images/icon-alert.svg'} alt="Alert Icon" style={{width: '32px', height: '32px'}}/>
              </span>
                        </div>
                        {
                            isClicked.ID ? <h2>Informasi</h2> : <h2>Information</h2>
                        }

                        {
                            isClicked.ID ? <p>Mengatur masukan konten dalam Bahasa Indonesia </p> :
                                <p> Set Content Input in English </p>
                        }
                        <div>
                            <button style={{...modalStyles.buttonConfirmation, textAlign: 'center'}} onClick={() => {
                                // setIsModalOpen(false)
                            }}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer',
};


const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '150px',
};


const buttonProcess: React.CSSProperties = {
    backgroundColor: 'lightgray',
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '150px',
};

const checkBoxStyle : React.CSSProperties = {
    boxSizing: "border-box",
    padding: "2px",
    marginTop: "10px",
    width: "20px",
    height: "20px",
    appearance: "none", // Removes default styling
    border: "1px solid lightgray", // Default border
    borderRadius: "2px",
    cursor: "pointer",
    backgroundColor: "white",
    transition: "border-color 0.2s, background-color 0.2s",
    display: "inline-block",
    textAlign: "center",
}

const checkBoxSpanStyle : React.CSSProperties = {
    position: "absolute",
    top: "10px",
    left: "4px",
    fontSize: "14px",
    fontWeight: "normal",
    color: "white",
    pointerEvents: "none",
}


const modalStyles = {
    overlay: {
        position: "fixed" as "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modal: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "20px",
        width: "400px",
        textAlign: "center" as "center",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    icon: {
        fontSize: "30px",
        color: "red",
        marginBottom: "10px",
    },
    buttonGroup: {
        display: "flex",
        marginTop: "4px",
    },
    buttonConfirmation: {
        backgroundColor: "#F58220",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        width: "50%",
        height: "40px"
    },
    buttonEN: {
        backgroundColor: '#F58220',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonInactiveEN: {
        backgroundColor: 'lightgray',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonID: {
        backgroundColor: '#F58220',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
    buttonInactiveID: {
        backgroundColor: 'lightgray',
        color: "#fff",
        padding: "10px 20px",
        cursor: "pointer",
        width: "10%",
        height: "40px",
        border: 'none',
    },
};

export default MenuEdit;
