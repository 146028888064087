import React, {useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper } from '@mui/material';
import { BannerTypeList} from "app/entities/banner/model/BannerListModel";


interface BannerTableProps {
    banners: BannerTypeList[];
    onEdit: (id: number) => void;
    onDelete: (id: number) => void;
}

const BannerTable: React.FC<BannerTableProps> = ({ banners, onEdit, onDelete }) => {

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const handleImageClick = (image: string) => {
        // Show the preview image
        setImagePreview(image);
        // You can also add other logic here, such as opening a modal or showing a larger preview
    };

    const handleClosePreview = () => {
        setImagePreview(null);
    };

    return (
        <TableContainer component={Paper} style={{ borderRadius: '8px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 'bold'}}>Created by</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Publish Date</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Target Link</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Preview</TableCell>
                        <TableCell style={{fontWeight: 'bold'}}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {banners.map((banner, index) => (
                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#F5FCFF' : 'white' }}>
                            <TableCell>
                                <div style={{color:'black', fontWeight: 'bold'}}>
                                    {banner.created_by}
                                </div>
                                <div className="font-size-14" style={{ color: '#636566' }}>
                                    {banner.created_date}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className="font-size-14" style={{ color: '#636566' }}>
                                    {banner.dateFrom } - { banner.dateTo }
                                </div>
                            </TableCell>
                            <TableCell>
                                <a href={banner.urlRedirect} target="_blank" rel="noopener noreferrer">
                                    {banner.urlRedirect}
                                </a>
                            </TableCell>
                            <TableCell>
                                    <img src={banner.preview} alt="Preview" style={{ width: '100px', cursor: 'pointer' }} onClick={() => handleImageClick(banner.preview)} />
                                    {imagePreview && (
                                        <div style={{
                                            position: 'fixed',
                                            top: '63%',
                                            left: '55%',
                                            transform: 'translate(-60%, -60%)',
                                            zIndex: 1,
                                            backgroundColor: '#f2f2f2',
                                            padding: '10px',
                                            border: '.5px solid #ddd',
                                            // width: '50%', // Matches the image width
                                            maxWidth: '600px', // Prevents it from growing too large
                                            minWidth: '300px', // Ensures it's not too small
                                            borderRadius: '18px', // Optional: adds rounded corners
                                            textAlign: 'center' // Centers content horizontally
                                        }}>
                                            <img src={imagePreview} alt="Preview"
                                                 style={{width: '400px', height: 'auto', margin: '0 auto'}}/>
                                            <button
                                                style={{
                                                    position: 'absolute',
                                                    top: '10px',
                                                    right: '10px',
                                                    background: 'red',
                                                    color: 'white',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    cursor: 'pointer',
                                                    padding: '5px 10px'
                                                }}
                                                onClick={() => handleClosePreview()}
                                            >
                                                X
                                            </button>
                                        </div>
                                    )}

                            </TableCell>
                            <TableCell>
                                <button className='btn btn-primary' type="submit" style={{marginRight: '8px'}}
                                        onClick={() => onEdit(banner.id)}>
                                    Edit
                                </button>
                                <button className='btn btn-danger' type="submit"
                                        onClick={(event) => onDelete(banner.id)}>
                                    Delete
                                </button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BannerTable;
