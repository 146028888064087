import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}

export interface IParams {
    key?: any
}

export const InvestmentManagerDetail = (props: IHeaderProps) => {
    const initialFormData = {
        date: new Date()
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("im_code")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataListOfSubAssetManagement, setDataListOfSubAssetManagement] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const parameter = props.location.state.key as IParams

    useEffect(() => {
        getListSubAssetManagement()
    }, [page, size, totalPage, keyword, formData, sort, ascending]);

    // get data
    const getListSubAssetManagement = () => {
        let code = !parameter[0] ? "" : parameter[0]
        let date = !formData.date ? "" : Number(moment(formData.date).format("YYYYMMDD"))
        let search = !keyword ? "" : keyword
        axios.get(`/services/consolidatedportfolioservice/api/asset-management/sub-account/${code}/data-transaksi/${date}?search=${search}&page=${page}&size=${size}&sort=${sort},${!ascending ? 'desc' : 'asc'}`).then((res) => {
            setDataListOfSubAssetManagement(res.data.data.content)
            setTotalPage(res.data.data.totalPages)
        }).catch((err) => {
            toast.error(translate(`${err.response.data.message}`));
        })
    }

    /* checked data table */
    const selectAll = () => {
        if (dataListOfSubAssetManagement.every(el => el.checked)) {
            const tempData = dataListOfSubAssetManagement.map(el => ({ ...el, checked: false }));
            setDataListOfSubAssetManagement(tempData);
        } else {
            const tempData = dataListOfSubAssetManagement.map(el => ({ ...el, checked: true }));
            setDataListOfSubAssetManagement(tempData);
        }
    }

    const checkOne = id => {
        const tempData = dataListOfSubAssetManagement.map(el => {
            if (el.ACNT_NO === id) {
                return { ...el, checked: !el.checked };
            }
            return el;
        });
        setDataListOfSubAssetManagement(tempData);
    }

    const createNewTC = () => {
        let subAccountNoList = []
        let date = !formData.date ? "" : Number(moment(formData.date).format("YYYYMMDD"))
        dataListOfSubAssetManagement.map((item, index) => {
            if (item.checked == true) {
                subAccountNoList.push(item.ACNT_NO)
            }
        })
        let payload = {
            subAccountNoList: subAccountNoList,
            date: date
        }
        history.push(`/investmentmanager/createnewtc`, payload)
    }

    const countType = (val) => {
        const countTypes = dataListOfSubAssetManagement.filter(el => el.checked === val);
        return countTypes.length;
    }

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-start">
                        <button className="btn px-0" onClick={() => history.goBack()}>
                            <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
                        </button>
                        <h1 className="font-family-spoqa mb-0 font-size-28 px-2">{parameter[0]} - {parameter[1]} </h1>
                    </div>
                </div>
            </div>
            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="mb-2 font-weight-bold">
                    Transaction Date
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-4">
                            <DatePicker
                                id={"selectedDate"}
                                dateFormat="yyyy-MM-dd"
                                selected={formData.date}
                                className={"form-control"}
                                scrollableYearDropdown
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChange={(val) => {
                                    const time = moment(new Date()).format("THH:mm:ss.00[Z]")
                                    setSelectedDate(val)
                                    setFormData({
                                        ...formData,
                                        date: val
                                    })
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-3 col-lg-6">
                            <button
                                className='btn btn-primary'
                                type="submit"
                                disabled={countType(true) >= 2 ? false : true}
                                onClick={
                                    () => createNewTC()
                                } >
                                <span className="bx bx bxs-file-plus my-0"></span> Create New TC
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3 col-lg-6">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-white py-3" value={keyword} placeholder="Cari Account" onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        getListSubAssetManagement()
                                    } else {
                                        setKeyword(e.target.value)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getListSubAssetManagement()
                                    }
                                }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card font-family-spoqa">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th className='text-center'>
                                        <input
                                            type="checkbox"
                                            checked={dataListOfSubAssetManagement.every(el => el.checked)}
                                            onChange={selectAll}
                                        />
                                    </th>
                                    <th>YP ID</th>
                                    <th>Sub Account Name</th>
                                    <th>Transaction Date</th>
                                    <th style={{ textAlign: 'right' }}>Grand Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataListOfSubAssetManagement ? dataListOfSubAssetManagement.map((item, index) => {
                                        return (
                                            <tr key={item?.ACNT_NO}>
                                                <td className='text-center'>
                                                    <input
                                                        type="checkbox"
                                                        checked={item.checked}
                                                        onChange={() => checkOne(item?.ACNT_NO)}
                                                    />
                                                </td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.ACNT_NO}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.ACNT_NM}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{!item?.ORD_DATE ? "" : moment(item?.ORD_DATE).format("DD MMMM YYYY")}</td>
                                                <td style={{ wordBreak: 'break-all', textAlign: 'right' }}>{item?.NET_AMOUNT}</td>
                                            </tr>
                                        )
                                    })
                                        : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div><div className="row mt-4 font-family-spoqa">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">Show</span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentManagerDetail);