import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {formatDate, formatDateShort, validateDate} from "app/shared/util/date-utils";
import {toast} from "react-toastify";
import {createBanner, getBannerList} from "app/entities/banner/services/BannerServices";

interface BannerFormProps {
    onImageUpdate: (file: File | null) => void;
    fileName: string | null;
    onClearFileName: () => void;
}

const payloadFormData = {
    type: "",
    image: null,
    url_redirect : "",
    datefrom : "",
    dateto : ""
}

const BannerForm: React.FC<BannerFormProps> = ({ onImageUpdate, fileName, onClearFileName}) => {
    let history = useHistory();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [datefrom, setDateFrom] = useState<string | null>(null);
    const [dateto, setDateTo] = useState<string | null>(null);
    const [url_redirect, setUrlRedirect] = useState('');
    const [targetPage, setTargetPage] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null); // To store image preview URL
    const [type,setType] = useState('mainbanner')
    const [formData, setFormData] = useState(payloadFormData)
    const [isLoading,setLoading] = useState(false)
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateEnd, setErrorDateEnd] = useState(false)
    const [errorUpload, setErrorUpload] = useState(false)


    const handleImageUpload = (item: React.ChangeEvent<HTMLInputElement>) => {

        try {
            if (item.target.files && item.target.files.length > 0) {
                const file = item.target.files[0];
                const imagePreviewUrl = URL.createObjectURL(file); // Generate a preview URL for the image
                setImageUrl(imagePreviewUrl); // Set the preview URL in state
                setImage(file); // Set the selected image in state
                onImageUpdate(file); // Call the onImageUpdate prop with the selected image
                // setFileName(file.name)
                setErrorUpload(false)
            } else {
                setErrorUpload(true)
            }

        } catch (error) {
            setErrorUpload(true)
            console.error("Error uploading file:", error);
        }
    };

    const handleClearFile = () => {
        onClearFileName()

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

    };

    const [errors, setErrors] = useState({
        startDate: false,
        endDate: false,
        targetUrl: false,
        image: false,
    });

    useEffect(() => {

        // if (image) {
        //     setImage(image);
        //     setFormData({
        //         ...formData,
        //         type,
        //         image,
        //         url_redirect,
        //         datefrom ,
        //         dateto
        //     })
        // }

        setImage(image);
        setFormData({
            ...formData,
            type,
            image,
            url_redirect,
            datefrom ,
            dateto
        })

    }, [image,datefrom,dateto,url_redirect]);

    const handleSubmit = (e: React.FormEvent) => {

        e.preventDefault();
        setLoading(true)

        const startDateError = !datefrom;
        const endDateError = !dateto;
        const targetUrlError = !url_redirect;
        const imageError = !image;

        setErrors({
            startDate: startDateError,
            endDate: endDateError,
            targetUrl: targetUrlError,
            image: imageError,
        });

        if (datefrom === '' || datefrom === null){
            setErrorDateFrom(true)
        }

        if (dateto === '' || dateto === null){
            setErrorDateEnd(true)
        }

        if (image === null){
            setErrorUpload(true)
        }

        if (startDateError || endDateError || imageError) {
            toast.warn("Please fill out all fields before submitting the form.", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
            setLoading(false)
            return;
        }

        saveSchedule(formData)
            .then((response) => {
                if (response.message === "success") {
                    history.push(`/stock/banner`);
                    toast.success("Success Create Banner",{autoClose: 3000})
                }

                setLoading(false)

            })

    };

    const handleClickBack = () => {
        history.push(`/stock/banner`);
    };

    const saveSchedule = async (payload: any) => {

        try {
            const formData = new FormData();
            formData.append('type', payload.type);
            if (payload.image) {
                formData.append('image', payload.image); // Include the file
            }
            formData.append('url_redirect', payload.url_redirect);
            formData.append('datefrom', payload.datefrom || '');
            formData.append('dateto', payload.dateto || '');

            return await createBanner(formData);
        } catch (error) {
            toast.error('Error while creating banner:', error.message);
        }
    };


    return (
        <form className="container" onSubmit={handleSubmit} style={formStyle}>
            <div className="col-16">
                <button className="btn px-0 mb-2" onClick={handleClickBack}>
                    <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                        <FontAwesomeIcon icon="arrow-left" color="black"/>
                    </div>
                </button>
            </div>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginRight: '10px'}}>
                    <img src="./content/images/file_image_banner.svg" alt="Image"
                         style={{width: '50px', height: '50px'}}/>
                </div>
                <div>
                    <h2 style={headerStyle}>Create Pop Up Banner</h2>
                    <p style={subTextStyle}>Create and Schedule Notifications for Our Customers</p>
                </div>
            </div>
            <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>

            {/* Publish Date */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Publish Date</label>
                <div style={dateFieldStyle}>
                    <div style={dateFieldContainer}>
                        <input
                            id="startDate"
                            type="date"
                            value={formatDate(datefrom) || ''}
                            onChange={(e) => {
                                if (validateDate(e.target.value, 'from')) {
                                    setDateFrom(e.target.value);
                                    setErrorDateFrom(false);
                                } else {
                                    toast.warn("The selected date must be the same as or later than today", {autoClose: 5000});
                                    setDateFrom('')
                                    setErrorDateFrom(true);

                                    // setStartDate(today.toISOString().slice(0, 10));clear
                                }
                            }}
                            style={errorDateFrom ? {...inputStyle, borderColor: 'red'} : inputStyle}
                            required
                        />

                    </div>

                    <span style={{marginLeft: '10px', marginRight: '10px'}}> - </span>

                    <div style={dateFieldContainer}>
                        <input
                            type="date"
                            value={formatDate(dateto) || ''}
                            onChange={(e) => {
                                if (validateDate(e.target.value, 'to',formatDate(datefrom))) {
                                    setDateTo(e.target.value)
                                    setErrorDateEnd(false)
                                } else {
                                    toast.warn("The end date must be later than the start date.", {autoClose: 5000});
                                    setDateTo('')
                                    setErrorDateEnd(true)
                                }
                            }}
                            style={errorDateEnd ? {...inputStyle, borderColor: 'red'} : inputStyle}
                            required
                        />
                    </div>

                </div>
                {errorDateFrom || errorDateEnd ?
                    <div style={dateFieldError}>
                        <div style={{color: errorDateFrom ? 'red' : "transparent", fontSize: 12, width: '100%',}}>
                            Please input Start Date
                        </div>
                        <span style={{marginLeft: '10px', marginRight: '10px', color: 'transparent'}}> - </span>
                        {errorDateEnd && (
                            <div style={{color: errorDateEnd ? 'red' : "transparent", fontSize: 12, width: '100%'}}>
                                Please input End Date
                            </div>
                        )}
                    </div> : null}

            </div>

            {/* Target URL */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target URL <span style={{fontWeight: 'normal'}}>(Optional)</span></label>
                <input
                    type="url"
                    placeholder="https://"
                    value={url_redirect || formData.url_redirect}
                    onChange={(e) => {
                        setUrlRedirect(e.target.value)
                        setFormData({...formData, url_redirect: e.target.value})
                    }}
                    style={inputStyle}
                />
            </div>

            {/* Target Page */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target Page <span style={{fontWeight: 'normal'}}>(Optional)</span></label>
                <input
                    type="url"
                    placeholder="Notification Page"
                    value={''}
                    disabled={true}
                    onChange={(e) => {
                    }}
                    style={inputStylePage}
                />
            </div>

            {/* Upload Image */}
            <div style={{position: 'relative'}}>
                <label style={labelStyle}>Upload Image</label>
                <div style={dateFieldContainer}>
                    <input
                        type="text"
                        value={fileName || ""}
                        placeholder="No File"
                        style={{
                            ...inputStyle,
                            paddingRight: '40px',
                            backgroundColor: 'transparent', // or the original background color
                            cursor: 'not-allowed',
                        }}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                handleClearFile();
                            }
                        }}
                        required
                    />
                    {fileName ?
                        <button style={{
                            position: 'absolute',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            color: 'red',
                            fontStyle: 'bold',
                            top: '70%',
                            right: '15%',
                        }}
                                onClick={handleClearFile}>X</button> : null}

                    <input
                        ref={fileInputRef}
                        type="file"
                        id="file-upload"
                        style={{
                            position: 'absolute',
                            padding: '5px',
                            transform: 'translateY(-50%)',
                            opacity: 0,
                        }}
                        onChange={handleImageUpload}
                    />
                    <label
                        htmlFor="file-upload"
                        style={{
                            position: 'absolute',
                            top: '70%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#ffffff',
                            border: '1px solid #f57c00',
                            borderRadius: '4px',
                            color: '#f57c00',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                        }}
                    >
                        Choose File
                    </label>
                </div>
            </div>

            {
                errorUpload && (
                    <div style={errorStyle}>
                        Please upload image file
                    </div>
                )
            }

            {/* Save Button */}
            <button
                type="submit"
                style={isLoading ? buttonProcess : buttonStyle}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div style={{alignItems: "center"}}>
                        <FontAwesomeIcon icon="spinner" spin style={{marginRight: 8}}/>
                        Creating...
                    </div>
                ) : (
                    "Save Schedule"
                )}
            </button>
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '700px',
    maxWidth: '700px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const subTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: '#666',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer'
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'not-allowed',
};

const dateFieldContainer: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

const dateFieldError: React.CSSProperties = {
    position: 'relative',
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const buttonProcess: React.CSSProperties = {
    backgroundColor: 'lightgray', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const errorStyle: React.CSSProperties = {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '10px',
    marginBottom: '14px',
    fontSize: '12px',
};

const calendarIcon: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-100%)',
    cursor: 'pointer',
};

export default BannerForm;
