import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {DataItem} from "app/entities/banner/model/BannerItemModel";
import {formatDate, validateDate} from "app/shared/util/date-utils";
import {toast} from "react-toastify";
import {updateBanner} from "app/entities/banner/services/BannerServices";

interface EditBannerFormProps {
    onImageUpdate: (file: File | null) => void;
    dataBanner: DataItem | null
    onClearFileName: () => void;
}

const payloadEditFormData = {
    type: "",
    image: null,
    urlRedirect : "",
    dateFrom : "",
    dateTo : "",
    id:"",
    nameValue: ""
}

const EditBannerForm: React.FC<EditBannerFormProps> = ({ onImageUpdate, dataBanner, onClearFileName }) => {
    let history = useHistory();

    const [image, setImage] = useState<File | null>(null);
    const [formData, setFormData] = useState(payloadEditFormData)
    const [isLoading,setIsLoading] = useState(false)
    const [isNewFile,setNewFile] = useState(false)
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateEnd, setErrorDateEnd] = useState(false)
    const [errorUpload, setErrorUpload] = useState(false)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName,setFileName] = useState('')
    const [isClearFileName, setClearName] = useState(false)


    const clickBack = () => {
        history.push(`/stock/banner`);
    }

    const [errors, setErrors] = useState({
        startDate: false,
        endDate: false,
        targetUrl: false,
    });

    useEffect(() => {
        if (dataBanner && !isClearFileName) {
            setFormData((prev) => ({
                ...prev,
                id: dataBanner.id || prev.id,
                type: dataBanner.type || prev.type,
                urlRedirect: prev.urlRedirect || dataBanner.urlRedirect || "",
                dateFrom: prev.dateFrom || dataBanner.dateFrom || "",
                dateTo: prev.dateTo || dataBanner.dateTo || "",
                nameValue: prev.nameValue || dataBanner.filename || '',
            }));
        }
    }, [dataBanner]);

    useEffect(() => {
        if (isNewFile) {
            setFormData((prev) => ({
                ...prev,
                image: image,  // Only update the image when a new file is uploaded
            }));
        }
    }, [image]);


    const handleInputChange = (field: string, value: any) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleEdit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true)
        const startDateError = !formData.dateFrom;
        const endDateError = !formData.dateTo;
        const targetUrlError = !formData.urlRedirect;
        const uploadError = isNewFile ? !fileName : !formData.nameValue;

        setErrors({
            startDate: startDateError,
            endDate: endDateError,
            targetUrl: targetUrlError,
        });

        if (startDateError){
            setErrorDateFrom(true)
        }

        if (endDateError){
            setErrorDateEnd(true)
        }

        if (isNewFile){
            if(!fileName){
                setErrorUpload(true)
                setIsLoading(false)
                return;
            } else{
                setErrorUpload(false)
            }
        } else {
            if (uploadError){
                setIsLoading(false)
                setErrorUpload(true)
                return;
            } else {
                setErrorUpload(false)
            }

        }

        if (startDateError || endDateError || uploadError ) {
            toast.warn("Please fill out all fields before submitting the form.", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
            setIsLoading(false)
            return;
        }

        submitEdit(formData)


    };

    const formatDateCheck = (dateString: string): string => {

        if (dateString.includes('/')) {
            const [day, month, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        } else {
            return dateString;
        }
    };

    const submitEdit = async (payload: any) => {

        let dateFrom = formatDateCheck(payload.dateFrom)
        let dateTo = formatDateCheck(payload.dateTo)

        try {
            const edtFormData = new FormData();
            edtFormData.append("id", payload.id);
            edtFormData.append("type", payload.type);
            // edtFormData.append("image", payload.image); // Include the file
            edtFormData.append("url_redirect", payload.urlRedirect);
            edtFormData.append("datefrom", dateFrom || "");
            edtFormData.append("dateto", dateTo || "");

            if (isNewFile){
                edtFormData.append("image", image); // Include the file
            } else {
                edtFormData.append("image", payload.image)
            }


            const response = await updateBanner(payload.id, edtFormData);

            if (response.message === "success"){
                history.push(`/stock/banner`);
                toast.success("Success Update Data Banner",{autoClose: 3000})
            }
            setIsLoading(false)
            return response.data
        } catch (error) {
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (errorData.stat_code === 400 && errorData.stat_msg === "image_file_required") {
                    toast.error("Image file is required");
                } else {
                    toast.error("Error while updating banner: " + error.response.data.stat_msg);
                }
            } else {
                toast.error("Error while updating banner: " + error.message);
            }
            setIsLoading(false)
        }
    };

    const editImageUpload = (item: React.ChangeEvent<HTMLInputElement>) => {

        const fileType = item.target.files[0].type;
        const fileSize = item.target.files[0].size;
        const isImage = fileType.startsWith('image/');
        const maxFileSize = 2 * 1024 * 1024;
        try {
            if (isImage && fileSize <= maxFileSize) {
                const file = item.target.files[0];
                const imagePreviewUrl = URL.createObjectURL(file); // Generate a preview URL for the image
                setImage(file)
                onImageUpdate(file);
                setNewFile(true)
                setErrorUpload(false)
                setFileName(file.name)

            } else {
                if (isImage) {
                    toast.error('File size exceeds the 2 MB limit.', { autoClose: 3000 });
                } else {
                    toast.error('Invalid file type. Only JPEG, JPG and PNG are allowed.', { autoClose: 3000 });
                }

                if(dataBanner.filename){
                    setErrorUpload(false)
                } else {
                    setErrorUpload(true)
                }
            }
        } catch (error) {
            setErrorUpload(true)
            console.error("Error uploading file:", error);
        }
    };

    const handleClearFile = () => {
        setClearName(true)
        if (formData.nameValue) {
            setFormData({...formData, nameValue: ""})
            onClearFileName()
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        if (fileName){
            setFileName("")
            onClearFileName()
        }

    }


    return (
        <form className="container" onSubmit={handleEdit} style={formStyle}>
            <div className="col-16">
                <button className="btn px-0 mb-2" onClick={clickBack}>
                    <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                        <FontAwesomeIcon icon="arrow-left" color="black"/>
                    </div>
                </button>
            </div>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginRight: '10px'}}>
                    <img src="./content/images/file_image_banner.svg" alt="Image"
                         style={{width: '50px', height: '50px'}}/>
                </div>
                <div>
                    <h2 style={headerStyle}>Edit Pop Up Banner</h2>
                    <p style={subTextStyle}>Create and Schedule Notifications for Our Customers</p>
                </div>
            </div>
            <hr style={{border: '0.5px solid lightgray', width: '100%'}}/>

            {/* Publish Date */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Publish Date</label>
                <div style={dateFieldStyle}>
                    <div style={dateFieldContainer}>
                        <input
                            type="date"
                            value={formatDate(formData.dateFrom)}
                            onChange={(e) => {
                                if (validateDate(e.target.value, 'from')) {
                                    setErrorDateFrom(false);
                                    formData.dateFrom = e.target.value
                                    // setFormData({...formData, dateFrom: e.target.value})
                                    handleInputChange("dateFrom", formData.dateFrom)
                                } else {
                                    toast.warn("The selected date must be the same as or later than today", {autoClose: 3000});
                                    formData.dateFrom = ''
                                    setErrorDateFrom(true);
                                }

                            }}
                            style={errorDateFrom ? {...inputStyle, borderColor: 'red'} : inputStyle}
                            required
                        />
                    </div>

                    <span style={{marginLeft: '10px', marginRight: '10px'}}> - </span>

                    <div style={dateFieldContainer}>
                        <input
                            type="date"
                            value={formatDate(formData.dateTo)}
                            onChange={(e) => {

                                if (validateDate(e.target.value, 'to', formatDate(formData.dateFrom))) {
                                    formData.dateTo = e.target.value
                                    handleInputChange("dateTo", formData.dateTo)
                                    setErrorDateEnd(false);
                                } else {
                                    toast.warn("The end date must be later than the start date.", {autoClose: 3000});
                                    formData.dateTo = ''
                                    setErrorDateEnd(true);
                                }

                            }}
                            style={errorDateEnd ? {...inputStyle, borderColor: 'red'} : inputStyle}
                            required
                        />
                    </div>

                </div>
                {errorDateFrom || errorDateEnd ?
                    <div style={dateFieldError}>
                        <div style={{color: errorDateFrom ? 'red' : "transparent", fontSize: 12, width: '100%',}}>
                            Please input Start Date
                        </div>
                        <span style={{marginLeft: '10px', marginRight: '10px', color: 'transparent'}}> - </span>
                        {errorDateEnd && (
                            <div style={{color: errorDateEnd ? 'red' : "transparent", fontSize: 12, width: '100%'}}>
                                Please input End Date
                            </div>
                        )}
                    </div> : null}
            </div>

            {/* Target URL */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target URL <span style={{fontWeight: 'normal'}}>(Optional)</span></label>
                <input
                    type="url"
                    placeholder="https://"
                    value={formData.urlRedirect}
                    onChange={(e) => handleInputChange("urlRedirect", e.target.value)}
                    style={inputStyle}
                />
            </div>

            {/* Target Page */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Target Page <span style={{fontWeight: 'normal'}}>(Optional)</span></label>

                <input
                    type="url"
                    placeholder="Notification Page"
                    value=''
                    onChange={(e) => handleInputChange("targetPage", e.target.value)}
                    style={inputStylePage}
                    required
                    disabled={true}
                />
            </div>
            {/* Upload Image */}
            <div style={{position: 'relative', marginBottom: '20px'}}>
                <label style={labelStyle}>Upload Image</label>
                <div style={dateFieldContainer}>
                    <input
                        type="text"
                        value={isNewFile ? fileName : formData.nameValue}
                        placeholder="No File"
                        style={{
                            ...inputStyle,
                            paddingRight: '40px',
                            backgroundColor: 'transparent', // or the original background color
                            cursor: 'not-allowed',
                        }}
                        onChange={(e) => {
                            if (e.target.value === "") {
                                handleClearFile();
                            }
                            handleInputChange("fileName", isNewFile ? fileName : formData.nameValue)
                        }}
                        required
                    />
                    {formData.nameValue || fileName ?
                        <button style={{
                            position: 'absolute',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            color: 'red',
                            fontStyle: 'bold',
                            top: '70%',
                            right: '15%',
                        }}
                                onClick={handleClearFile}>X</button> : null}

                    <input
                        ref={fileInputRef}
                        type="file"
                        id="file-upload"
                        style={{
                            position: 'absolute',
                            padding: '5px',
                            transform: 'translateY(-50%)',
                            opacity: 0,
                        }}
                        onChange={editImageUpload}
                    />
                    <label
                        htmlFor="file-upload"
                        style={{
                            position: 'absolute',
                            top: '70%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#ffffff',
                            border: '1px solid #f57c00',
                            borderRadius: '4px',
                            color: '#f57c00',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                        }}
                    >
                        Choose File
                    </label>
                </div>
            </div>

            {
                errorUpload && (
                    <div style={errorStyle}>
                        Please upload image file
                    </div>
                )
            }

            {/* Save Button */}
            <button
                type="submit"
                style={isLoading ? buttonProcess : buttonStyle}
                onClick={handleEdit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div style={{ alignItems: "center" }}>
                        Updating...
                    </div>
                ) : (
                    "Update Schedule"
                )}
            </button>
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '700px',
    maxWidth: '700px',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const subTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: '#666',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
    cursor: 'not-allowed',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'not-allowed',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'not-allowed',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '150px',
};

const btnSelectImage: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    border: "1px solid #f57c00",
    borderRadius: "5px",
    backgroundColor: "white",
    color: "#f57c00",
    fontSize: "14px",
    cursor: "pointer",
    fontWeight: "normal",
}

const dateFieldContainer: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
};

const dateFieldError: React.CSSProperties = {
    position: 'relative',
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
};

const errorStyle: React.CSSProperties = {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '10px',
    marginBottom: '14px',
    fontSize: '12px',
};

const buttonProcess: React.CSSProperties = {
    backgroundColor: 'lightgray', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '150px',
};

export default EditBannerForm;
