// ConfigCareer.tsx
export const baseUrlAPI = () => {
  return `${process.env.REACT_APP_MASI_API_URL}/career`;
};

export const urlWebCareer = () => {
  return `${process.env.REACT_APP_MASI_WEB_CAREER_URL}/detail-career/`;
};

export const apiKeyTinyMCE: string = `${process.env.REACT_APP_TINYMCE_APIKEY}`;
