import React, {useEffect, useState} from "react";
import PreviewBannerMobile from "app/entities/banner/main-menu/create-banner/PreviewBannerMobile";
import BannerForm from "app/entities/banner/main-menu/create-banner/BannerForm";
import {toast} from "react-toastify";
import useNetworkStatus from "app/utils/NetworkStatus";

export const CreateBannerContent: React.FC = () => {
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const { isOnline } = useNetworkStatus();
    const [fileName, setFileName] = useState<string | null>(null);

    const handleImageUpdate = (file: File | null) => {

        try {
            if (file) {

                const fileType = file.type;
                const isImage = fileType.startsWith('image/');
                const maxFileSize = 2 * 1024 * 1024;

                if (isImage  && file.size <= maxFileSize) {
                    const reader = new FileReader();
                    reader.abort();
                    reader.onload = (e) => {
                        setImagePreview(e.target?.result as string);

                    }
                    setFileName(file.name);
                    reader.readAsDataURL(file);

                }  else {
                    if (isImage) {
                        toast.error('File size exceeds the 2 MB limit.', { autoClose: 3000 });
                    } else {
                        toast.error('Invalid file type. Only JPEG, JPG and PNG are allowed.', { autoClose: 3000 });
                    }
                }

            } else {
                setImagePreview(null);
                setFileName(null);
            }
        } catch (error){
            toast.error(error)

        }
    };

    const handleClearImage = () => {
        setImagePreview(null);
        setFileName(null);

        const fileInput = document.getElementById("file-input") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";  // Ensures the same file can be uploaded again
        }

    };

    const handleClearFileName = () => {
        setFileName(null);
        setImagePreview(null);
    };

    useEffect(() => {
        if (!isOnline) {
            toast.error('You are offline. Please check your internet connection.', { autoClose: 3000 });
        }
    }, [isOnline]);

    return (
        <div className="container" style={{display: 'flex', gap: '10px', padding: '10px'}}>
            <div style={{flex: 1}}>
                <BannerForm
                    onImageUpdate={handleImageUpdate}
                    fileName={fileName}
                    onClearFileName={handleClearFileName}
                />
            </div>
            <div style={{flex: 1}}>
                <PreviewBannerMobile
                    imageUrl={imagePreview || undefined}
                    onClearImage={handleClearImage}
                />
            </div>
        </div>
    );
};
