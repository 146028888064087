import React, {useEffect, useState} from 'react';
import PaginationComponent from './PaginationComponent';
import BannerHeader from "app/entities/banner/main-menu/BannerHeader";
import BannerTable from "app/entities/banner/main-menu/BannerTable";
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {BannerType} from "app/entities/banner/model/BannerListModel";
import {deleteBanner, getBannerList} from "app/entities/banner/services/BannerServices";
import {useHistory} from "react-router-dom";
import useNetworkStatus from "app/utils/NetworkStatus";
import {toast} from "react-toastify";

const BannerContent: React.FC = () => {
    const [banners, setBanners] = useState<BannerType['data']>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [size,setSize] = useState<number>(10)
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bannerIdToDelete, setBannerIdToDelete] = useState<number>(null);

    let history = useHistory();

    const { isOnline } = useNetworkStatus();

    useEffect(() => {

        if (!isOnline) {
            toast.error('You are offline. Please check your internet connection.', { autoClose: 3000 });
        }

        fetchBanners(currentPage,size);
    }, [currentPage,size,isOnline]);

    const fetchBanners = async (page: number, size: number) => {
        setIsLoading(true);
        try {
            const response = await getBannerList(page,size);
            setBanners(response.data);
            setTotalPages(parseInt(response.pagination.total_page))
            // Assume response includes pagination metadata:
            // setTotalPages(response.totalPages || 1); // need get total page
        } catch (error) {
            setIsLoading(false);
            throw error
        } finally {
            setIsLoading(false);
        }
    };


    const handleEdit = (id: number) => {

        history.push(`/stock/banner/edit/${id}`)
    };

    const handleDelete = async() => {
        setIsModalOpen(false);
        try {
            await deleteBanner(bannerIdToDelete)
            await fetchBanners(currentPage,size);
        } catch (error){
            throw error
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchBanners(currentPage,size)
    };

    const handleSizeChange = (size: number) => {
        setSize(size);
        setCurrentPage(1);
        fetchBanners(currentPage,size)

    };


    return (
        <div style={{padding: '16px'}}>
            <BannerHeader/>
            {isLoading ? (
                    <div>Loading banners...</div>
                ) :
                (
                    <BannerTable banners={banners} onEdit={handleEdit} onDelete={(id) => {
                        setIsModalOpen(true)
                        setBannerIdToDelete(id)
                    }}/>
                )}
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '16px'}}>
                <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    size={size}
                    onPageChange={handlePageChange}
                    onSizeChange={handleSizeChange}
                />
            </div>

            {isModalOpen && (
                <div style={modalStyles.overlay}>
                    <div style={modalStyles.modal}>
                        <div style={modalStyles.icon}>
              <span role="img" aria-label="Warning">
                <img src={'./content/images/icon-alert.svg'} alt="Alert Icon" style={{width: '32px', height: '32px'}}/>
              </span>
                        </div>
                        <h2>Delete Activity</h2>
                        <p>Are you sure you want to delete this activity?</p>
                        <div style={modalStyles.buttonGroup}>
                            <button style={modalStyles.cancelButton} onClick={() => setIsModalOpen(false)}>
                                Cancel
                            </button>
                            <button style={modalStyles.deleteButton} onClick={() => handleDelete()}>
                                Yes, Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({document}: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;

const modalStyles = {
    overlay: {
        position: "fixed" as "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modal: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "20px",
        width: "400px",
        textAlign: "center" as "center",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    icon: {
        fontSize: "30px",
        color: "red",
        marginBottom: "10px",
    },
    buttonGroup: {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    },
    cancelButton: {
        backgroundColor: "#004085",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        width: "40%",
        height: "40px"
    },
    deleteButton: {
        backgroundColor: "red",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        width: "40%",
        height: "40px"
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerContent)

