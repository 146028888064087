import React from 'react';
import { Typography } from '@mui/material';
import {useHistory} from "react-router-dom";

const MenuHeader: React.FC = () => {
    let history = useHistory();
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
            <div style={{padding: '16px'}}>
                <Typography style={{fontSize: '18pt'}}>
                    <b>Menu Management</b>
                </Typography>
                <Typography style={{display: 'none'}} variant="body1" color={"darkgray"} fontSize={14}>
                    Content Management to manage all news publish
                </Typography>
            </div>
            <div style={{padding: '14', fontSize: '16pt', color: '#F58220'}}>
                <button className='btn btn-primary' style={{width : '150px'}} type="submit" onClick={() => {
                    history.push(`menu/create`)
                }}>
                    Add new
                </button>
            </div>
        </div>

    );
};

export default MenuHeader;
