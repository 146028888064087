import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import '../../../../../../../scss/app.min.scss';
import useWindowDimensions from "app/utils/ScreenDimesions";
import PencilIcon from "app/component/pencil-icon";
import UploadAdd from "app/component/upload-add";
import axios from "axios";
import Select from 'react-select';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconDevelopmentApps from 'app/component/icon-development-apps';
import {
  faHome,
  faBriefcase,
  faPaperPlane,
  faQuestion,
  faImage,
  faCopy,
  faBox,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { AUTHORITIES } from 'app/config/constants';
import { connect } from 'react-redux';
import NewIcon from "app/component/new-icon";

const SideBar = ({ isOpen, toggle, account }) => {

  let history = useHistory();
  const { height, width } = useWindowDimensions();
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const [showKaryawan, setShowKaryawan] = useState<any>(false)
  const [canUpload, setCanUpload] = useState<any>(false)
  const [canCreate, setCanCreate] = useState<any>(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const [modal, setModal] = useState(false);
  const [isAdminGeneral, setIsAdminGeneral] = useState<any>(false)
  const [isAdminDMSCondition, setIsAdminDMSCondition] = useState<any>(false)
  const [isOpenManagement, setIsOpenManagement] = useState(false)
  const [isOpenMarketing, setOpenMarketing] = useState(false)
  const [isOpenResearchReport, setOpenResearchReport] = useState(false)
  const [isListMenu, setListMenu] = useState([])

  let path = window.location.pathname

  useEffect(() => {
    iscanCreate()
    isAdminHC()
    isAdminDMS()
    adminGeneral()
    getMenuDocument()
  }, []);

  const getMenuDocument = () => {
    axios.get(`/services/documentservice/api/documents/dropdown-document-create`)
      .then(async (res) => {
        const data = res.data.map((item, index) => {
          return { label: item.value, value: item.key }
        })
        setListMenu(data)
      }).catch((err) => {
        console.log('err', err.response)
      })
  }

  const iscanCreate = () => {
    axios.get(`/services/uaadocservice/api/is-user-may-create`).then(async (res) => {
      if (res.data) {
        setCanCreate(true)
      } else {
        setCanCreate(false)
      }
    }).catch((err) => {
      setCanCreate(false)
    })
  }

  const isAdminHC = () => {
    axios.get(`/services/uaadocservice/api/is-admin-hc`)
        .then(async (res) => {
          if (res.data) {
            setShowKaryawan(true)
          } else {
            setShowKaryawan(false)
          }
        }).catch((err) => {
      throw new Error(err.response.data.message);
    })
  }

  const isAdminDMS = () => {
    axios.get(`/services/uaadocservice/api/is-admin-dms`)
      .then(async (res) => {
        if (res.data) {
          setIsAdminDMSCondition(true)
        } else {
          setIsAdminDMSCondition(false)
        }
      }).catch((err) => {
      throw new Error(err.response.data.message);
      })
  }

  const adminGeneral = () => {
    axios.get(`/services/uaadocservice/api/is-admin`).then(async (res) => {
      if (res.data) {
        setIsAdminGeneral(true)
      } else {
        setIsAdminGeneral(false)
      }
    }).catch((err) => {
      throw new Error(err.response.data.message);
    })
  }

  const handleModal = () => {
    setModal(!modal);
  }

  const handleClose = () => {
    setModal(false)
  };

  const handleNext = async () => {
    if (selectedOption.value === "IMO") {
      history.push('/draft/create/new', {
        from: "home",
        isAdmin: isAdminDMSCondition
      })
    } else {
      history.push('/draft/releaseapproval/create/new',{
        from: "home"
      })
    }
    await setModal(false)
    await setSelectedOption(null)
  };

  const toggleManagement = () => setIsOpenManagement(!isOpenManagement);

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })} style={{ display: 'block' }}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>
      </div>
      <div className="vertical-menu" id="sidebar-menu" style={isOpen ? { display: 'block' } : {}}>
        <div data-simplebar className="h-100">
          <div id="sidebar-menu" >
            <Modal className="font-family-spoqa" isOpen={modal} toggle={handleClose} size="md" centered={true}>
              <ModalHeader toggle={handleClose}>
                <h2>Type Document</h2>
                <span style={{ fontSize: '12px' }}>Select document type that you want to create</span>
              </ModalHeader>
              <ModalBody>
                <div className="m-3">
                  <div className="mb-2">
                    Select
                  </div>
                  <FormGroup>
                    <Select
                      placeholder={"Choose type"}
                      defaultValue={selectedOption}
                      onChange={(e) => setSelectedOption(e)}
                      options={isListMenu}
                    />
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="success" onClick={() => handleNext()} disabled={!selectedOption}>
                  Next
                </Button>
              </ModalFooter>
            </Modal>
            <ul className="metismenu list-unstyled" id="side-menu">
              <div className="font-family-spoqa d-flex justify-content-center ml-4 mr-4 mb-3 mt-2">
                <button type="button" className="btn btn-primary bg-shadow w-100" disabled={!canCreate} onClick={() => handleModal()}>
                  <PencilIcon className="mr-1" />
                  Create Document
                </button>
              </div>
              {/* <hr className="mx-4 my-1" />
              <li className="font-family-spoqa">
                <a href="/draft/upload/new" className=" waves-effect">
                  <button type="button" className="btn btn-primary bg-shadow w-100 " disabled={!canCreate}>
                    <UploadAdd className="mr-1" />
                    Upload file
                  </button>
                </a>
              </li> */}

              <li className="font-family-spoqa">
                <a href="/" className="waves-effect">
                  <i className="bx bxs-home"></i>
                  <span>Home</span>
                </a>
              </li>
              <li className="font-family-spoqa">
                <a href="/draft" className=" waves-effect">
                  <i className="bx bxs-file"></i>
                  <span>Draft</span>
                </a>
              </li>
              <li className="font-family-spoqa">
                <a href="/announcement/list" className=" waves-effect">
                  <i className="bx bxs-megaphone"></i>
                  <span>Announcement</span>
                </a>
              </li>
              <li className="font-family-spoqa">
                <a href="/history" className=" waves-effect">
                  <i className="bx bx-history"></i>
                  <span>History</span>
                </a>
              </li>
              <li className="font-family-spoqa">
                <a href="/listofapprovers" className=" waves-effect">
                  <i className="bx bx-spreadsheet"></i>
                  <span>Approvers</span>
                </a>
              </li>
              {account.authorities.includes(AUTHORITIES.ONE_ASIA) && <>
                <li className="font-family-spoqa">
                  <a href="/investmentmanager" className=" waves-effect">
                    <i className="bx bx-spreadsheet"></i>
                    <span>Investment Manager</span>
                  </a>
                </li>
              </>}
              {account.authorities.includes(AUTHORITIES.CAREER) && <>
                <li className="font-family-spoqa">
                  <a href="/career/candidates" className=" waves-effect">
                    <i className="bx bx-group"></i>
                    <span>Candidates </span>
                  </a>
                </li>
                <li className="font-family-spoqa">
                  <a href="/career/jobs" className=" waves-effect">
                    <i className="bx bx-briefcase"></i>
                    <span>List Jobs </span>
                  </a>
                </li>
              </>}
              {account.authorities.includes(AUTHORITIES.WHISTLEBLOWING) && <>
                <li className="font-family-spoqa">
                  <a href="/whistleblow/report" className=" waves-effect">
                    <i className="bx bx-group"></i>
                    <span>Whistleblow </span>
                  </a>
                </li>
              </>}
              {
                showKaryawan ?
                  <li className="font-family-spoqa">
                    <a className=" waves-effect" onClick={() => {
                      if (openDropdown == 1) {
                        setOpenDropdown(null)
                      } else {
                        setOpenDropdown(1)
                      }
                    }}>
                      <i className="bx bx-user-plus"></i>
                      <span>Karyawan</span>
                    </a>
                  </li> : <></>
              }
              <Collapse isOpen={openDropdown == 1}>
                <li className="font-family-spoqa ml-4">
                  <a href="/levelgroup" className="waves-effect">
                    <span>Level Organization</span>
                  </a>
                </li>
                <li className="font-family-spoqa ml-4">
                  <a href="/group" className=" waves-effect">
                    <span>Group Karyawan</span>
                  </a>
                </li>
                <li className="font-family-spoqa ml-4">
                  <a href="/karyawan" className=" waves-effect">
                    <span>List Karyawan</span>
                  </a>
                </li>
              </Collapse>
              {isAdminGeneral ?
                <li className="font-family-spoqa">
                  <a className=" waves-effect" onClick={toggleManagement}>
                    <i className="bx bx-columns"></i>
                    <span>Management</span>
                  </a>
                </li>
                : <></>
              }
              <Collapse isOpen={isOpenManagement}>
                <li className="font-family-spoqa ml-4">
                  <a href="/role/menumanagement" className=" waves-effect">
                    <span>Menu Management</span>
                  </a>
                </li>
                <li className="font-family-spoqa ml-4">
                  <a href="/role/rolemanagement" className=" waves-effect">
                    <span>Role Management</span>
                  </a>
                </li>
                <li className="font-family-spoqa ml-4">
                  <a href="/role/authoritymanagement" className=" waves-effect">
                    <span>User Management</span>
                  </a>
                </li>
                <li className="font-family-spoqa ml-4">
                  <a href="/role/rolemenu" className=" waves-effect">
                    <span>Role Menu</span>
                  </a>
                </li>
              </Collapse>

              {account.authorities.includes(AUTHORITIES.MARKETING) && <>
                <li className="font-family-spoqa">
                  <a className=" waves-effect" onClick={() => { setOpenMarketing(!isOpenMarketing); }}>
                    <i className="bx bxs-store"></i>
                    <span>Marketing</span>
                  </a>
                </li>

                <Collapse isOpen={isOpenMarketing}>
                  <li className="font-family-spoqa ml-4">
                    <a href="/url-short" className=" waves-effect">
                      <span>URL Shorterner</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/linktree" className=" waves-effect">
                      <span>Linktree</span>
                    </a>
                  </li>
                </Collapse>
              </>}

              {account.authorities.includes(AUTHORITIES.AML) && <>
                <li className="font-family-spoqa">
                  <a href="/aml" className="waves-effect">
                    <i className="bx bxs-file-blank"></i>
                    <span>AML</span>
                  </a>
                </li>
              </>}

              {account.authorities.includes(AUTHORITIES.RESEARCH_REPORT) && <>
                <li className="font-family-spoqa">
                  <a className="waves-effect" onClick={() => { setOpenResearchReport(!isOpenResearchReport); }}>
                    <i className="bx bxs-collection"></i>
                    <span>Research Report</span>
                  </a>
                </li>

                <Collapse isOpen={isOpenResearchReport}>
                  <li className="font-family-spoqa ml-4">
                    <a href="/summary-analyst" className=" waves-effect">
                      <span>Summary Analyst</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/rating-and-target" className=" waves-effect">
                      <span>Rating and Target</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/profile-analyst" className=" waves-effect">
                      <span>Profile Analyst</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/task-status" className=" waves-effect">
                      <span>Task Status</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/make-new-request" className=" waves-effect">
                      <span>Make New Request</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/list-report-requested" className=" waves-effect">
                      <span>List Report Requested</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/trend-analyst" className=" waves-effect">
                      <span>Trend Analyst</span>
                    </a>
                  </li>
                  <li className="font-family-spoqa ml-4">
                    <a href="/approval-analyst" className=" waves-effect">
                      <span>Approval Analyst</span>
                    </a>
                  </li>
                </Collapse>

              </>}

              {width < 1025 &&
                  <li className="font-family-spoqa">
                    <a href="/usermanual" className="waves-effect d-flex">
                      <i className="bx bxs-file-blank"></i>
                      <div className="d-flex align-items-center" style={{fontSize:"11px"}}><span className={"mr-1"}>USER MANUAL GUIDE</span> </div>
                    </a>
                  </li>
              }

            </ul>
          </div>
        </div>
      </div>
    </div >
  )
};

const submenus = [
  [
    {
      title: "Menu 1",
      target: "Menu-1",
    },
    {
      title: "Menu 2",
      target: "Menu-2",
    },
    {
      title: "Menu 3",
      target: "Menu-3",
    },
  ],
  [
    {
      title: "Setting 1",
      target: "Setting-1",
    },
    {
      title: "Setting 2",
      target: "Setting-2",
    },
    {
      title: "Setting 3",
      target: "Setting-3",
    },
  ],
];

// export default SideBar;

const mapStateToProps = ({ authentication }) => ({
  account: authentication.account,
});


const mapDispatchToProps = {
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
