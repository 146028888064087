import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import ErrorMessage from "app/component/error-message/ErrorMessage";
import {ValidateImageUpload} from "app/shared/util/image-utils";

const payloadFormData = {
    title: "",
    resume: "",
    description : "",
    sourceAuthor : "",
    fileNameID : "",
    fileNameEN : "",
    fileNameAttachment : "",
    imageID : null,
    imageEN : null,
    attachment : null
}

const NewsCreate: React.FC = () => {
    let history = useHistory();
    const [formData, setFormData] = useState(payloadFormData)
    const [isLoading,setLoading] = useState(false)
    let imageFileID = null
    let imageFileEN = null
    let imageFileAttach = null

    const onBackPressed = () => {
        history.push(`/corporate/news`);
    };

    const onUploadImageID = (item: React.ChangeEvent<HTMLInputElement>) => {
        console.log("MASI IMAGE ID")
        try {
            if (item.target.files && item.target.files.length > 0) {
                imageFileID = item.target.files[0];
                const { valid, message } = ValidateImageUpload(imageFileID);

                setErrors({
                    ...errors,
                    imageID: ''
                })

                if (!valid){
                    setErrors({
                        ...errors,
                        imageID: message
                    })
                } else {

                    setFormData({
                        ...formData, fileNameID: imageFileID.name, imageID: imageFileID
                    })
                }

            } else {
                setErrors({
                    ...errors,
                    imageID: "Check your type or file size"
                })
            }

        } catch (error) {
            setErrors({
                ...errors,
                imageID: "Error when uploading file"
            })
        }
    };

    const onUploadImageEN = (item: React.ChangeEvent<HTMLInputElement>) => {

        console.log("MASI IMAGE EN")
        try {
            if (item.target.files && item.target.files.length > 0) {
                imageFileEN = item.target.files[0];
                const { valid,message } = ValidateImageUpload(imageFileEN);

                setErrors({
                    ...errors,
                    imageEN: ''
                })
                if (!valid){
                    setErrors({
                        ...errors,
                        imageEN: message
                    })
                } else {
                    setFormData({
                        ...formData,
                        fileNameEN: imageFileEN.name,
                        imageEN: imageFileEN
                    })
                }
            } else {
                setErrors({
                    ...errors,
                    imageEN: "Check your type or file size"
                })
            }

        } catch (error) {
            setErrors({
                ...errors,
                imageEN: "Error when uploading file"
            })
        }
    };

    const onUploadAttachment = (item: React.ChangeEvent<HTMLInputElement>) => {

        try {
            if (item.target.files && item.target.files.length > 0) {
                imageFileAttach = item.target.files[0];
                const { valid, message } = ValidateImageUpload(imageFileAttach);
                setErrors({
                    ...errors,
                    attachment: ''
                })
                if (!valid){
                    setErrors({
                        ...errors,
                        attachment: message
                    })

                } else {
                    setFormData({
                        ...formData,
                        fileNameAttachment: imageFileAttach.name,
                        attachment: imageFileAttach
                    })

                }

            } else {
                setErrors({
                    ...errors,
                    attachment: "Check your type or file size"
                })
            }

        } catch (error) {
            setErrors({
                ...errors,
                attachment: "Error when uploading file"
            })

        }
    };

    const [errors, setErrors] = useState({
        title: '',
        resume: '',
        description: '',
        sourceAuthor: '',
        imageID: '',
        imageEN: '',
        attachment: '',
    });

    const errorSubmit = {...errors}

    const onClearFile = (type: 'imageID' | 'imageEN' | 'attachment') => {
        switch (type) {
            case 'imageID':
                setFormData({ ...formData, fileNameID: '', imageID: null });
                setErrors({...errors, imageID: ''})
                break;
            case 'imageEN':
                setFormData({ ...formData, fileNameEN: '', imageEN: null });
                setErrors({...errors, imageEN: ''})
                break;
            case 'attachment':
                setFormData({ ...formData, fileNameAttachment: '', attachment: null });
                setErrors({...errors, attachment: ''})
                break;
            default:
                console.error('Invalid file type');
        }
    };


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (!formData.title) errorSubmit.title = 'Title is required';
        if (!formData.resume) errorSubmit.resume = 'Resume is required';
        if (!formData.description) errorSubmit.description = 'Description is required';
        if (!formData.sourceAuthor) errorSubmit.sourceAuthor = 'Source Author is required';

        setErrors(errorSubmit)
    };



    const saveSchedule = async (payload: any) => {

        try {
            const formData = new FormData();
            formData.append('type', payload.type);
            if (payload.image) {
                formData.append('image', payload.image); // Include the file
            }
            formData.append('url_redirect', payload.url_redirect);
            formData.append('datefrom', payload.datefrom || '');
            formData.append('dateto', payload.dateto || '');

            // return await createBanner(formData);
        } catch (error) {
            toast.error('Error while creating banner:', error.message);
        }
    };


    return (
        <form className="container" onSubmit={handleSubmit} style={formStyle}>
            <div style={{marginBottom: '20px'}}>
                <div className="col-16">
                    <button className="btn px-0 mb-2" onClick={onBackPressed}>
                        <div className="font-family-spoqa mb-0 font-size-20" style={{color: 'black'}}>
                            <FontAwesomeIcon icon="arrow-left" color="black"/>
                        </div>
                    </button>
                </div>
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <div style={{marginRight: '10px', display: 'none'}}>
                        <img src="./content/images/file_image_banner.svg" alt="Image"
                             style={{width: '50px', height: '50px'}}/>
                    </div>
                    <div>
                        <h2 style={headerStyle}>Create News</h2>
                    </div>
                </div>
            </div>

            <hr style={{border: '0.5px solid lightgray', width: '100%', display: 'none'}}/>

            {/* Title */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Title</label>
                <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => {
                        setFormData({...formData, title: e.target.value})
                    }}
                    style={inputStyle}
                />
                <ErrorMessage message={errors.title}/>
            </div>

            {/* Resume */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Resume </label>
                <textarea
                    value={formData.resume}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            resume: e.target.value
                        })
                    }}
                    style={inputDescriptionStyle}
                />
                <ErrorMessage message={errors.resume}/>
            </div>

            {/* Description */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Description</label>
                <textarea
                    value={formData.description}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            description: e.target.value
                        })
                    }}
                    style={inputDescriptionStyle}
                />
                <ErrorMessage message={errors.description}/>
            </div>

            {/* Source / Author */}
            <div style={fieldGroupStyle}>
                <label style={labelStyle}>Source / Author</label>
                <input
                    type="text"
                    value={formData.sourceAuthor}
                    onChange={(e) => {
                        setFormData({
                            ...formData,sourceAuthor: e.target.value
                        })
                    }}
                    style={inputStylePage}
                />
                <ErrorMessage message={errors.sourceAuthor} />
            </div>

            {/* Image ID */}
            <div style={{position: 'relative', marginBottom: '20px'}}>
                <label style={labelStyle}>Image ID</label>
                <div style={dateFieldContainer}>
                    <input
                        type="text"
                        value={formData.fileNameID}
                        style={{
                            ...inputStyle,
                            paddingRight: '40px',
                            backgroundColor: 'transparent', // or the original background color
                            cursor: 'not-allowed',
                        }}
                        required
                    />
                    {formData.fileNameID ?
                        <button style={{
                            position: 'absolute',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            color: 'red',
                            fontStyle: 'bold',
                            top: errors.imageID ? '50%':'70%',
                            right: '8%',
                        }}
                                onClick={()=> onClearFile('imageID')}>X</button> : null}

                    <input
                        // ref={fileInputRef}
                        type="file"
                        id="upload-image-id"
                        style={{
                            position: 'absolute',
                            padding: '5px',
                            transform: 'translateY(-50%)',
                            opacity: 0,
                        }}
                        onChange={onUploadImageID}
                    />
                    <label
                        htmlFor="upload-image-id"
                        style={{
                            position: 'absolute',
                            top: errors.imageID ? '50%' : '70%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#ffffff',
                            border: '1px solid #f57c00',
                            borderRadius: '4px',
                            color: '#f57c00',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                        }}
                    >
                        Select File
                    </label>
                </div>
                <ErrorMessage message={errors.imageID} />
            </div>

            {/* Image EN */}
            <div style={{position: 'relative', marginBottom: '20px'}}>
                <label style={labelStyle}>Image EN</label>
                <div style={dateFieldContainer}>
                    <input
                        type="text"
                        value={formData.fileNameEN}
                        style={{
                            ...inputStyle,
                            paddingRight: '40px',
                            backgroundColor: 'transparent', // or the original background color
                            cursor: 'not-allowed',
                        }}
                        required
                    />
                    <div>
                        {formData.imageEN ?
                            <button style={{
                                position: 'absolute',
                                transform: 'translateY(-50%)',
                                border: 'none',
                                backgroundColor: 'transparent',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontWeight: 'normal',
                                color: 'red',
                                fontStyle: 'bold',
                                top: errors.imageEN ? '50%':'70%',
                                right: '8%',
                            }}
                                    onClick={()=> onClearFile('imageEN')}>X</button> : null}
                    </div>

                    <input
                        // ref={fileInputRef}
                        type="file"
                        id="upload-image-en"
                        style={{
                            position: 'absolute',
                            padding: '5px',
                            transform: 'translateY(-50%)',
                            opacity: 0,
                        }}
                        onChange={onUploadImageEN}
                    />
                    <label
                        htmlFor="upload-image-en"
                        style={{
                            position: 'absolute',
                            top: errors.imageEN ? '50%' : '70%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#ffffff',
                            border: '1px solid #f57c00',
                            borderRadius: '4px',
                            color: '#f57c00',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                        }}
                    >
                        Select File
                    </label>
                </div>
                <ErrorMessage message={errors.imageEN} />
            </div>

            {/* Attachment */}
            <div style={{position: 'relative'}}>
                <label style={labelStyle}>Attachment</label>
                <div style={dateFieldContainer}>
                    <input
                        type="text"
                        value={formData.fileNameAttachment}
                        style={{
                            ...inputStyle,
                            paddingRight: '40px',
                            backgroundColor: 'transparent', // or the original background color
                            cursor: 'not-allowed',
                        }}
                        required
                    />
                    <div>
                        {formData.attachment ?
                            <button style={
                                {
                                    position: 'absolute',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: 'normal',
                                    color: 'red',
                                    fontStyle: 'bold',
                                    top: errors.attachment ? '50%':'70%',
                                    right: '8%',
                                }}
                                    onClick={()=> onClearFile("attachment")}>X</button> : null}
                    </div>

                    <input
                        // ref={fileInputRef}
                        type="file"
                        id="upload-image-attachment"
                        style={{
                            position: 'absolute',
                            padding: '5px',
                            transform: 'translateY(-50%)',
                            opacity: 0,
                        }}
                        onChange={onUploadAttachment}
                    />
                    <label
                        htmlFor="upload-image-attachment"
                        style={{
                            position: 'absolute',
                            top: errors.attachment ? '50%' : '70%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            backgroundColor: '#ffffff',
                            border: '1px solid #f57c00',
                            borderRadius: '4px',
                            color: '#f57c00',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            padding: '4px 8px',
                        }}
                    >
                        Select File
                    </label>
                </div>
                <ErrorMessage message={errors.attachment} />
            </div>


            {/* Save Button */}
            <button
                type="submit"
                style={isLoading ? buttonProcess : buttonStyle}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div style={{alignItems: "center"}}>
                        Submitting...
                    </div>
                ) : (
                    "Submit"
                )}
            </button>
        </form>
    );
};

// Styles
const formStyle: React.CSSProperties = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    margin: '20px auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
};

const headerStyle: React.CSSProperties = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
};

const fieldGroupStyle: React.CSSProperties = {
    marginBottom: '20px',
};

const labelStyle: React.CSSProperties = {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    color: 'black',
    fontWeight: 'bold'
};

const inputStyle: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer'
};

const inputStylePage: React.CSSProperties = {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

const inputDescriptionStyle: React.CSSProperties = {
    width: '100%',
    height: '150px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    caretColor: 'auto', // or any other color you want
    boxSizing: 'border-box',
    overflowY: 'hidden',
    verticalAlign: 'top',
};

const dateFieldContainer: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
};

const dateFieldStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

const dateFieldError: React.CSSProperties = {
    position: 'relative',
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
};

const buttonStyle: React.CSSProperties = {
    backgroundColor: '#f97316', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const buttonProcess: React.CSSProperties = {
    backgroundColor: 'lightgray', // Orange color
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '20px',
    width: '150px',
};

const btnRemoveStyle: React.CSSProperties = {
    position: 'absolute',
    transform: 'translateY(-50%)',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'red',
    fontStyle: 'bold',
    top: '50%',
    right: '8%',
}

export default NewsCreate;
